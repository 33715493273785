/**
 * This is a singleton to ensure we only instantiate Stripe once.
 */
import { Stripe, loadStripe } from "@stripe/stripe-js";

const getStripe = async (publicKey?: string | null): Promise<Stripe | null> => {
  if (publicKey) {
    return loadStripe(publicKey);
  }

  return null;
};

export default getStripe;
