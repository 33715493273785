/* eslint-disable max-len */
import { FC } from "react";

import Head from "next/head";

type TMetaProps = {
  title?: string;
  keywords?: string;
  description?: string;
  image?: string;
  url?: string;
  locale?: string;
  noIndex?: boolean;
  canonicalUrl?: string | undefined;
};

const Meta: FC<TMetaProps> = ({ title, description, image, keywords, url, locale, noIndex, canonicalUrl }) => {
  return (
    <Head>
      {/* Title */}
      <title>{title}</title>
      <meta name="og:title" content={title} />

      {/* Description */}
      <meta name="og:description" content={description} />
      <meta name="description" content={description} />
      <meta name="twitter:description" content={description} />

      {/* Images */}
      <meta name="og:image" content={image} />
      <meta name="image" content={image} />
      <meta property="twitter:image" content={image} />
      <meta property="twitter:card" content="summary_large_image" />

      {/* Other */}
      <meta property="og:url" content={url} />
      <meta name="keywords" content={keywords} />
      <meta property="locale" content={locale} />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />

      {noIndex && <meta name="robots" content="noindex" />}
      {canonicalUrl && <link rel="canonical" href={`${process.env.NEXT_PUBLIC_CLIENT_BACK_END_URL}/${canonicalUrl}`} />}
    </Head>
  );
};

Meta.defaultProps = {
  title: "OBDeleven",
  keywords: "obd eleven, obd, vehicle, performance",
  description: "Start conversation with your car",
};

export default Meta;
