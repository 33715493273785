import { AxiosPromise } from "axios";

import { API_ROUTES } from "@utils/api-routes";
import api from "@utils/axios/public";

import { TConfig } from "./types";

/**
 * @deprecated only used in configSlice, no where else, should removed in the future
 */
const getConfig = (): AxiosPromise<TConfig> => api.get<TConfig>(API_ROUTES.siteSettings);

const configService = {
  getConfig,
};

export default configService;
