import { menuAnatomy as parts } from "@chakra-ui/anatomy";
import { cssVar, defineStyle, createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(parts.keys);

const $bg = cssVar("menu-bg");

const baseStyleItem = defineStyle({
  _active: { bg: "black" },
  _focus: { bg: "black" },
  _hover: {
    bg: "whiteAlpha.200",
  },
  bg: $bg.reference,
});

const baseStyleList = defineStyle({
  [$bg.variable]: "black",
  color: "white",
  borderRadius: "none",
  borderWidth: "0",
  bg: $bg.reference,
  zIndex: "docked",
});

const baseStyleButton = defineStyle({
  transitionProperty: "common",
  transitionDuration: "normal",
  color: "white",
  _hover: {
    color: "white",
  },
});

const baseStyle = definePartsStyle({
  list: baseStyleList,
  button: baseStyleButton,
  item: baseStyleItem,
});

// variant - light
const light = definePartsStyle({
  list: {
    bg: "white",
    color: "black",
    borderRadius: "default",
    boxShadow: "0px 5px 5px 0px rgba(0,0,0,0.15)",
    p: 2,
  },
  item: {
    bg: "white",
    _active: {
      bg: "gray.200",
    },
    _focus: {
      bg: "gray.200",
    },
    _hover: {
      bg: "gray.200",
    },
    _notLast: {
      mb: 1,
    },
    borderRadius: "default",
    py: 4,
    px: 2,
  },
  button: {
    fontWeight: "normal",
    bg: "gray.50",
    _active: {
      bg: "gray.50",
    },
    _hover: {
      bg: "gray.50",
    },
    color: "black",
    whiteSpace: "normal",
    colorScheme: "gray",
  },
});

export const menuTheme = defineMultiStyleConfig({
  baseStyle,
  variants: {
    light,
  },
});

export default menuTheme;
