import { SystemStyleObject } from "@chakra-ui/react";

export const scrollbarStyles: SystemStyleObject = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "&::-webkit-scrollbar": {
    width: "4px",
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "&::-webkit-scrollbar-track": {
    width: "6px",
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "&::-webkit-scrollbar-thumb": {
    background: "gray.300",
    borderRadius: "24px",
  },
};

export const hiddenScrollbarStyles: SystemStyleObject = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "&::-webkit-scrollbar": {
    display: "none",
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "&::-webkit-scrollbar-track": {
    width: "3px",
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "&::-webkit-scrollbar-thumb": {
    background: "#dfa",
    borderRadius: "24px",
  },
};
