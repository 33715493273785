import { FC } from "react";

import { MenuItem, Link as ChakraLink } from "@chakra-ui/react";
import Link from "next/link";

interface IProfileDropdowmMenuItemProps {
  text: string;
  isExternal?: boolean;
  href?: string;
  onLinkClick?: () => void;
}

const ProfileDropdownMenuItem: FC<IProfileDropdowmMenuItemProps> = ({ text, onLinkClick, isExternal, href }) => {
  return (
    <MenuItem _hover={{ bg: "black" }} _active={{ bg: "black" }} _focus={{ bg: "black" }}>
      {onLinkClick ? (
        <ChakraLink color="white" fontWeight="medium" onClick={onLinkClick}>
          {text}
        </ChakraLink>
      ) : (
        <Link href={href || ""} passHref>
          <ChakraLink as="span" color="white" fontWeight="medium" isExternal={isExternal}>
            {text}
          </ChakraLink>
        </Link>
      )}
    </MenuItem>
  );
};

export default ProfileDropdownMenuItem;
