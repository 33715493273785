import { FC } from "react";

import { Box } from "@chakra-ui/react";

type TMobileNavigationToggleProps = {
  onToggle: () => void;
  isOpen: boolean;
};

const MobileNavigationToggle: FC<TMobileNavigationToggleProps> = ({ onToggle, isOpen }) => {
  return (
    <Box onClick={onToggle} cursor="pointer" position="relative" w="28px" h="28px">
      <Box
        w="18px"
        h="2px"
        bg="white"
        transform={!isOpen ? "translate(-50%, -50%) rotate(0deg)" : "translate(-50%, -50%) rotate(45deg)"}
        transition="transform 0.3s"
        position="absolute"
        top={!isOpen ? "35%" : "50%"}
        left="50%"
      />
      <Box
        w="18px"
        h="2px"
        bg="white"
        transform={!isOpen ? "translate(-50%, -50%) rotate(0deg)" : "translate(-50%, -50%) rotate(-45deg)"}
        transition="transform 0.3s"
        position="absolute"
        top={!isOpen ? "65%" : "50%"}
        left="50%"
      />
    </Box>
  );
};

export default MobileNavigationToggle;
