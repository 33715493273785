export const VERTICAL_ALIGN_VALUE = {
  top: "start",
  center: "center",
  bottom: "end",
};

/* eslint-disable @typescript-eslint/naming-convention */
export const HORIZONTAL_ALIGN_VALUE = {
  left: "left",
  center: "center",
  right: "right",
  "space-around": "space-around",
  "space-between": "space-between",
  "space-evenly": "space-evenly",
};

export type TVerticalAlignValue = keyof typeof VERTICAL_ALIGN_VALUE;
export type THorizontalAlignValue = keyof typeof HORIZONTAL_ALIGN_VALUE;
