import { FC } from "react";

import { Menu, MenuList, MenuButton, Flex } from "@chakra-ui/react";
import useTranslation from "next-translate/useTranslation";

import { useAppStaticPath } from "@context/AppStaticPathContext";
import { useSessionUtils } from "@utils/hooks/hooks";
import { convertUrlToPath } from "@utils/utils";

import ProfileIcon from "../assets/icons/Account.svg";
import ProfileDropdownMenuItem from "./ProfileDropdownMenuItem";

type TProfileDropdownProps = {
  userNavLinks: TNavigationLink[];
};

const ProfileDropdown: FC<TProfileDropdownProps> = ({ userNavLinks }) => {
  const { t } = useTranslation("navigation");
  const { isLoggedIn, signIn, signOut } = useSessionUtils();
  const { accountPath } = useAppStaticPath();

  return (
    <Flex data-test-id="profileDropdown" alignItems="center">
      <Menu isLazy>
        <MenuButton _active={{ color: "white" }}>
          <ProfileIcon fill="white" />
        </MenuButton>
        <MenuList bg="black" border="none" borderRadius="0">
          {!isLoggedIn ? (
            <>
              <ProfileDropdownMenuItem text={t("navigation:login")} onLinkClick={() => signIn()} />
              {userNavLinks.map((navItem) => (
                <ProfileDropdownMenuItem
                  text={navItem.title || ""}
                  href={navItem.external ? navItem.url : convertUrlToPath(navItem.url)}
                  isExternal={navItem.newTab}
                  key={navItem.code}
                />
              ))}
            </>
          ) : (
            <>
              <ProfileDropdownMenuItem text={t("navigation:account")} href={accountPath} />
              {userNavLinks.map((navItem) => (
                <ProfileDropdownMenuItem
                  text={navItem.title || ""}
                  href={navItem.external ? navItem.url : convertUrlToPath(navItem.url)}
                  isExternal={navItem.newTab}
                  key={navItem.code}
                />
              ))}
              <ProfileDropdownMenuItem text={t("navigation:logout")} onLinkClick={() => signOut()} />
            </>
          )}
        </MenuList>
      </Menu>
    </Flex>
  );
};

export default ProfileDropdown;
