import { FC } from "react";

import { Box } from "@chakra-ui/react";
import Link from "next/link";

// eslint-disable-next-line import/no-absolute-path
import Logo from "/public/assets/obdeleven/logo.svg";

const ObdLogo: FC = () => {
  return (
    <Box data-test-id="obdElevenLogo" as={Link} href="/" height="auto">
      <Box as={Logo} height={{ base: "28px", md: "32px" }} />
    </Box>
  );
};

export default ObdLogo;
