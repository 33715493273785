import React, { FC } from "react";

type TMessage = {
  message: string;
};

const Loader: FC<TMessage> = ({ message }) => {
  return (
    <div className="loader-container">
      <div className="loader" />
      <span className="loader-text">{message}</span>
    </div>
  );
};

export default Loader;
