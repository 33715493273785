import { FC, ReactNode } from "react";

import { HStack, Text, Box } from "@chakra-ui/react";

type TNavigationItemProps = {
  value: string | undefined;
  isActive?: boolean;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  onClick?: () => void;
};

const NavigationItem: FC<TNavigationItemProps> = ({ value, onClick, leftIcon, rightIcon, isActive }) => {
  return (
    <HStack
      w="full"
      justify="space-between"
      borderBottom="1px solid"
      borderColor="whiteAlpha.300"
      py="4"
      cursor="pointer"
      onClick={onClick}
      color={isActive ? "white" : "gray.400"}
    >
      <HStack>
        {leftIcon}
        <Text textTransform="uppercase" fontWeight="medium">
          {value}
        </Text>
      </HStack>
      <Box color="gray.400">{rightIcon}</Box>
    </HStack>
  );
};

NavigationItem.defaultProps = {
  isActive: true,
};

export default NavigationItem;
