/**
 * Core app routes.
 * Static paths are controlled by admin and this is config base with our static pages routing.
 * Specific `bySlug`, `byId` routes and etc. must go to the `StaticRoutesPathContext`
 */
export const APP_ROUTES = {
  home: "/",
  orderStatus: "/order-status",
  cartRecovery: "/cart-recovery",
  cart: "/cart",
  checkout: "/checkout",
  products: "/products",
  compare: "/compare",
  account: {
    base: "/account",
    newAddress: "/account/addresses/new",
    editAddress: "/account/addresses/:id",
    addresses: "/account/addresses",
    orders: "/account/orders",
    order: "/account/orders/:id",
  },
  affiliate: "/affiliate",
  blog: "/blog",
  signOut: "/auth/signout",
  support: "/support",
  placedOrder: "/placed-order/:token/:email",
  contentPreview: "/content-preview",
} as const;

export const BLACKLISTED_URLS = [APP_ROUTES.orderStatus];

/**
 * `fallback` contain only the end of route part.
 * `fallback` property is used when admin is not filled route and use during path join.
 */
export const DESTINATION_BY_CODE = {
  compare: {
    path: APP_ROUTES.compare,
    fallback: "/compare",
    code: "compare",
  },
  products: {
    path: APP_ROUTES.products,
    fallback: "/products",
    code: "products",
  },
  newAddress: {
    path: APP_ROUTES.account.newAddress,
    fallback: "/new",
    code: "new-address",
  },
  editAddress: {
    path: APP_ROUTES.account.editAddress,
    fallback: "/:id",
    code: "edit-address",
  },
  addresses: {
    path: APP_ROUTES.account.addresses,
    fallback: "/addresses",
    code: "addresses",
  },
  orders: {
    path: APP_ROUTES.account.orders,
    fallback: "/orders",
    code: "orders",
  },
  order: {
    path: APP_ROUTES.account.order,
    fallback: "/order/:id",
    code: "orders/:id",
  },
  account: {
    path: APP_ROUTES.account.base,
    fallback: "/account",
    code: "account",
  },
  cart: {
    path: APP_ROUTES.cart,
    fallback: "/cart",
    code: "cart",
  },
  checkout: {
    path: APP_ROUTES.checkout,
    fallback: "/checkout",
    code: "checkout",
  },
  orderStatus: {
    path: APP_ROUTES.orderStatus,
    fallback: "/order-status",
    code: "order-status",
  },
  blog: {
    path: APP_ROUTES.blog,
    fallback: "/blog",
    code: "blog",
  },
  signOut: {
    path: APP_ROUTES.signOut,
    fallback: "/auth/signout",
    code: "signOut",
  },
  support: {
    path: APP_ROUTES.support,
    fallback: "/support",
    code: "support",
  },
  placedOrder: {
    path: APP_ROUTES.placedOrder,
    fallback: "/placed-order",
    code: "placedOrder",
  },
} as const;

type TDestinationRouteKey = keyof typeof DESTINATION_BY_CODE;
export type TAppStaticPath = { [Key in TDestinationRouteKey as `${Key}Path`]: string };
export type TDestinationByCodeKey = typeof DESTINATION_BY_CODE[keyof typeof DESTINATION_BY_CODE]["code"];
export type TDestinationByPathKey = typeof DESTINATION_BY_CODE[keyof typeof DESTINATION_BY_CODE]["path"];
