import React, { useEffect, FC, useRef } from "react";

import { Box } from "@chakra-ui/react";
import { OnShippingChangeData, OnShippingChangeActions } from "@paypal/paypal-js";
import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";

import { useAppToast } from "@components/Toast/hooks/UseAppToast";
import { useAppStaticPath } from "@context/AppStaticPathContext";
import { useUserLocalization } from "@modules/Localization";
import { useAppDispatch, useAppSelector } from "@utils/hooks/hooks";
import { logger } from "@utils/logger/client";
import { waitForOrderToBeReady } from "@utils/utils";
import cartService from "features/cart/cartActions";
import { initExpressCheckout, approveExpressCheckout, updateExpressCheckout } from "features/cart/cartSlice";
import { selectConfig } from "features/config/configSlice";
import { ICartItemOptions, PaymentMethodCode } from "features/types";

import singleProductHandler from "./singleProductHandlerUtils";
import { getTempPaypalCartToken, removeTempPaypalCartToken } from "./utils";

interface IPaypalExpressProps {
  cartToken?: string;
  product?: string;
  disabled?: boolean;
  cartItemOptions?: ICartItemOptions;
}

type TPayPalFormProps = {
  showSpinner: boolean;
} & IPaypalExpressProps;

const ButtonWrapper: FC<TPayPalFormProps> = ({ cartToken, product, showSpinner, disabled, cartItemOptions }) => {
  const toast = useAppToast();
  const [{ options }] = usePayPalScriptReducer();
  const router = useRouter();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { getOrderStatusPath } = useAppStaticPath();
  const cartItemOptionsRef = useRef(cartItemOptions);
  const productCodeRef = useRef(product);

  useEffect(() => {
    dispatch({
      type: "resetOptions",
      value: {
        ...options,
      },
    });
  }, [showSpinner]);

  // Instead of using `forceRender` and having blinking button, update required values via `ref`
  useEffect(() => {
    cartItemOptionsRef.current = cartItemOptions;
  }, [cartItemOptions]);

  useEffect(() => {
    productCodeRef.current = product;
  }, [product]);

  const handleCreateOrder = async () => {
    if (productCodeRef.current && cartItemOptionsRef.current) {
      return singleProductHandler.getPaymentId({
        method: PaymentMethodCode.PAYPAL,
        productCode: productCodeRef.current,
        cartItemOptions: cartItemOptionsRef.current,
      });
    }

    await cartService.getCart();
    return dispatch(initExpressCheckout({ method: "paypal" }))
      .unwrap()
      .then((payment) => {
        return payment.paymentOrderId;
      });
  };

  const handleOnApprove = async () => {
    const tempCartToken = productCodeRef.current ? getTempPaypalCartToken() : cartToken;
    return dispatch(
      approveExpressCheckout({
        shippingData: null,
        cartToken: tempCartToken,
      })
    ).then(() =>
      waitForOrderToBeReady(tempCartToken).then(() => {
        if (!tempCartToken) {
          return;
        }

        removeTempPaypalCartToken();
        router.push(getOrderStatusPath(tempCartToken), undefined, { locale: router.locale });
      })
    );
  };

  const handleOnError = () => {
    toast.error({ description: t("common:unknown-error-occurred") });
  };

  const handleOnShippingChange = async (data: OnShippingChangeData, actions: OnShippingChangeActions) => {
    const tempCartToken = productCodeRef.current ? getTempPaypalCartToken() : cartToken;
    return dispatch(
      updateExpressCheckout({
        updateData: data,
        cartToken: tempCartToken,
      })
    ).then((response) => {
      logger.debug("shippingchange");

      if (!response.payload?.updatedData?.shippingOptions?.length) {
        actions.reject();
      }
    });
  };

  return (
    <Box zIndex={0} position="relative">
      <PayPalButtons
        style={{ height: 48 }}
        disabled={disabled}
        fundingSource="paypal"
        createOrder={handleCreateOrder}
        onApprove={handleOnApprove}
        onShippingChange={handleOnShippingChange}
        onError={handleOnError}
      />
    </Box>
  );
};

const PayPalExpress: FC<IPaypalExpressProps> = ({ cartToken, product, disabled, cartItemOptions }) => {
  const config = useAppSelector(selectConfig);
  const { currency } = useUserLocalization();

  return (
    <PayPalScriptProvider
      options={{
        // eslint-disable-next-line @typescript-eslint/naming-convention
        "client-id": config?.config?.paypal_public_key || "",
        components: "buttons",
        currency,
      }}
    >
      <ButtonWrapper
        cartToken={cartToken}
        product={product}
        cartItemOptions={cartItemOptions}
        showSpinner
        disabled={disabled}
      />
    </PayPalScriptProvider>
  );
};

export default PayPalExpress;
