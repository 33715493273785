export const fontSizes = {
  textSize: {
    size24: "24px",
    title: "22px",
    regular: "18px",
    bodyText: "16px",
    labels: "14px",
    small: "12px",
    micro: "10px",
  },
  desktopHeadingSize: {
    1: "64px",
    2: "56px",
    3: "34px",
    4: "24px",
  },
  mobileHeadingSize: {
    1: "34px",
    2: "28px",
    3: "24px",
    4: "20px",
  },
};
