import { accordionAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/styled-system";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseStyleContainer = defineStyle({
  borderTopWidth: "0",
  borderColor: "inherit",
  _last: {
    borderBottomWidth: "1px",
  },
});

const baseStyleButton = defineStyle({
  transitionProperty: "common",
  transitionDuration: "normal",
  fontSize: "textSize.title",
  borderRadius: 0,
  fontWeight: "medium",
  justifyContent: "space-between",
  borderBottomWidth: "1px",
  lineHeight: "1.2",
  _focusVisible: {
    boxShadow: "outline",
  },
  _hover: {
    bg: "inherit",
  },
  _disabled: {
    opacity: 0.4,
    cursor: "not-allowed",
  },
  py: 4,
  pl: 0,
});

const baseStylePanel = defineStyle({
  pt: "6",
  px: 0,
  pb: "7",
});

const baseStyleIcon = defineStyle({
  fontSize: "1.25em",
});

const baseStyle = definePartsStyle({
  container: baseStyleContainer,
  button: baseStyleButton,
  panel: baseStylePanel,
  icon: baseStyleIcon,
});

const accordionTheme = defineMultiStyleConfig({ baseStyle });

export default accordionTheme;
