export enum CheckoutStep {
  CUSTOMER = "CUSTOMER",
  SHIPPING = "SHIPPING",
  ADDRESS = "ADDRESS",
  PAYMENT = "PAYMENT",
  PAYMENT_STATUS = "PAYMENT_STATUS",
}

export type TStepSummaryInfo = {
  name: string;
  value: string | undefined;
};
