const colors = {
  primary: {
    50: "#E6F2FF",
    100: "#B1D5FF",
    200: "#7FB5FF",
    300: "#5194FF",
    400: "#2971FF",
    500: "#004EFF",
    600: "#002BDC",
    700: "#0007B0",
    800: "#00007D",
    900: "#000048",
  },
  secondary: {
    50: "#FFEAEA",
    100: "#FFBABD",
    200: "#FF8A93",
    300: "#FF576C",
    400: "#F00E4A",
    500: "#D4002D",
    600: "#B10014",
    700: "#8A0001",
    800: "#5F0000",
    900: "#340000",
  },
  errors: {
    500: "#D4002D",
  },
  success: {
    50: "#E6F6EE",
    100: "#B4E5CC",
    200: "#81D1AC",
    300: "#4ABD8D",
    400: "#00A771",
    500: "#009058",
    600: "#007741",
    700: "#005F2D",
    800: "#00461C",
    900: "#002D0E",
  },
  warning: {
    50: "#FFF2DB",
    100: "#FFE5B7",
    200: "#FFD793",
    300: "#FFCB6E",
    400: "#FFBE46",
    500: "#FFB100",
    600: "#D0910F",
    700: "#A37215",
    800: "#785515",
    900: "#423012",
  },
  gray: {
    50: "#F7F7F7",
    100: "#EFEFEF",
    150: "#DEDEDE",
    200: "#D9D9D9",
    300: "#AFAFAF",
    400: "#8B8B8B",
    500: "#555555",
    600: "#373737",
    700: "#252525",
    800: "#1C1C1C",
    900: "#111111",
  },
  blackAlpha: {
    300: "rgba(0, 0, 0, 0.1)",
    600: "rgba(0, 0, 0, 0.4)",
    700: "rgba(0, 0, 0, 0.7)",
  },
  common: {
    black: "rgba(17, 17, 17, 1)",
    text: "rgba(17, 17, 17, 0.7)",
    gray: "rgba(17, 17, 17, 0.1)",
  },
};

export default colors;
