/* eslint-disable @typescript-eslint/naming-convention */
const NodeCache = require("node-cache");

const { LOCALES, DEFAULT_LOCALE } = require("./configs/translations");

const cache = new NodeCache({ stdTTL: 600, checkperiod: 600, useClones: false });

module.exports = {
  locales: Object.keys(LOCALES),
  defaultLocale: DEFAULT_LOCALE,
  pages: {
    "*": [
      "common",
      "cart",
      "navigation",
      "validation",
      "error",
      "messages",
      "user",
      "product",
      "vehicles",
      "features",
      "plans",
      "compare",
      "blog",
      "vin",
    ],
    "rgx:^/products": [],
    "rgx:^/account": ["account", "orderStatus", "order"],
    "/content-preview": [],
    "rgx:^/view-order": ["checkout"],
    "/cart": ["coupon"],
    "/checkout": ["coupon", "checkout"],
    "/demo": [],
    "/order-status": ["checkout"],
    "/support": ["support"],
  },
  loadLocaleFrom: async (lang, ns) => {
    const localeCode = `${lang}_${lang.toUpperCase()}`;
    const resource = `locales/${localeCode}/${ns}`;

    const data = cache.get(resource);

    if (cache.has(resource) && data) {
      return data.data;
    }

    const baseUrl =
      process.env.NEXT_STAGE === "build"
        ? process.env.NEXT_PUBLIC_CLIENT_BACK_END_URL
        : process.env.NEXT_PUBLIC_BACK_END_URL;

    return fetch(`${baseUrl}/shop-api/${resource}`, {
      cache: "force-cache",
    })
      .then(async (res) => {
        const data = await res.json();
        console.warn(`Translations response status, "${baseUrl}/shop-api/${resource}": ${res.status}`);

        cache.set(resource, { date: new Date(), data });

        return data;
      })
      .catch(() => {
        return null;
      });
  },
};
