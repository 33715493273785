/* eslint-disable promise/no-nesting */
/* eslint-disable @typescript-eslint/naming-convention */
import { FC, useCallback, useEffect, useState } from "react";

import { getCookie } from "cookies-next";
import dynamic from "next/dynamic";

import cartService from "@features/cart/cartActions";
import { selectCart } from "@features/cart/cartSlice";
import { ICartItemOptions, IProduct } from "@features/types";
import { useAppSelector } from "@utils/hooks/hooks";

import singleProductHandler from "./singleProductHandlerUtils";
import { getTempStripeCartToken, removeTempStripeCartToken } from "./utils";

const BraintreePaypalExpress = dynamic(() => import("@components/checkout/utils/express/BraintreePaypalExpress"), {
  ssr: false,
});

const BraintreeApplePayExpress = dynamic(() => import("@components/checkout/utils/express/BraintreeApplePayExpress"), {
  ssr: false,
});

const BraintreeGooglePayExpress = dynamic(
  () => import("@components/checkout/utils/express/BraintreeGooglePayExpress"),
  { ssr: false }
);

const isIOS = getCookie("Is-iOS");

interface Props {
  product?: IProduct;
  cartItemOptions?: ICartItemOptions;
  disabled?: boolean;
  cartToken?: string;
  isWebView?: boolean;
}

const BraintreeExpress: FC<Props> = (props) => {
  const { product, cartItemOptions, cartToken, isWebView } = props;
  // const [localCartToken, setLocalCartToken] = useState(cartToken);
  const [authorization, setAuthorization] = useState<string | null>(null);

  const cart = useAppSelector(selectCart);
  let orderDetails: any;

  if (product && cartItemOptions) {
    orderDetails = {
      code: product.code,
      singleProduct: true,
    };
  } else if (cart.cart) {
    orderDetails = {
      code: null,
      singleProduct: false,
    };
  }

  const getCartToken = useCallback(
    (invalidate = false): Promise<string> => {
      return new Promise((resolve) => {
        if (cartToken && !invalidate) {
          resolve(cartToken);
          return;
        }

        if (orderDetails.singleProduct && cartItemOptions) {
          const token = getTempStripeCartToken();

          if (token) {
            resolve(token);
          } else {
            singleProductHandler
              .createNewCart({
                productCode: orderDetails.code,
                cartItemOptions,
              })
              .then((response: string) => {
                resolve(response);
              });
          }
        } else {
          throw new Error("");
        }
      });
    },
    [cartItemOptions, cartToken, orderDetails.code, orderDetails.singleProduct]
  );

  const setTokens = useCallback(async () => {
    getCartToken().then((token) => {
      cartService.getBraintreeClientToken(token).then(({ token: authorization }) => setAuthorization(authorization));
    });
  }, [getCartToken]);

  useEffect(() => {
    setTokens();
  }, [setTokens, cartToken]);

  const getInvalidatedCartToken = useCallback(() => {
    removeTempStripeCartToken();
    return getCartToken(true);
  }, [getCartToken]);

  if (!authorization) {
    return null;
  }

  const paymentProps = {
    ...props,
    authorization,
    cartToken,
    onGetCartToken: getInvalidatedCartToken,
  };

  if (!isWebView) {
    return (
      <>
        <BraintreeApplePayExpress {...paymentProps} />
        <BraintreeGooglePayExpress {...paymentProps} />
        <BraintreePaypalExpress {...paymentProps} />
      </>
    );
  }

  if (isIOS) {
    return <BraintreeApplePayExpress {...paymentProps} isWebView />;
  }

  return <BraintreeGooglePayExpress {...paymentProps} isWebView />;
};

export default BraintreeExpress;
