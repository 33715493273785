import { LOCALES } from "@configs/translations";
import { IGeoLocalization } from "@utils/types";

import { TLocalization } from "./types";

export const parseXLocalization = (xLocalization?: string | null): TLocalization | null => {
  if (!xLocalization) {
    return null;
  }

  const [country, locale, currency] = xLocalization.split(",");
  return {
    country,
    locale,
    currency,
  };
};

export const getXLocalizationHeader = (l: TLocalization): string => {
  return `${l.country || ""},${l.locale || ""},${l.currency || ""}`;
};

export const mergeLocalizationSources = (
  geoData: IGeoLocalization,
  preferences: TLocalization,
  locale: string | undefined
): TLocalization => ({
  ...(geoData?.currency ? { currency: geoData.currency } : {}),
  ...preferences,
  ...(geoData?.country_code ? { country: geoData.country_code } : {}),
  ...(locale ? { locale: LOCALES[locale] } : {}),
});

export const parseJSONCookie = (cookie?: string | null): Record<string, string> | null => {
  if (!cookie) {
    return null;
  }

  try {
    return JSON.parse(decodeURIComponent(cookie));
  } catch (e) {
    return {};
  }
};
