import axios, { AxiosInstance } from "axios";

const createAxiosInstance = (): AxiosInstance => {
  const instance = axios.create({ baseURL: "/" });

  return instance;
};

const api = createAxiosInstance();

export default api;
