import { AxiosPromise } from "axios";

import api from "@utils/axios/public";

import { IBlock, ResourceCode } from "./types";

const getBlockByCode = (code: ResourceCode): AxiosPromise<IBlock> => api(`/blocks/${code}`);

const cmsService = {
  getBlockByCode,
};

export default cmsService;
