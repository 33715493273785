import { ButtonProps } from "@chakra-ui/button";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { Button, forwardRef } from "@chakra-ui/react";

const LinkButton = forwardRef<ButtonProps, "a">((props, ref) => (
  <Button ref={ref} variant="link" rightIcon={<ArrowForwardIcon />} {...props}>
    {props.children}
  </Button>
));

export default LinkButton;
