import { FC } from "react";

import { HStack, VStack } from "@chakra-ui/react";
import Link from "next/link";

import BadgeImage from "@components/BadgeImage";
import { Heading } from "@components/Heading/Heading";

type TProviderLinkListProps = {
  width?: string;
  height?: string;
  label?: string;
  spacing?: string;
  providersList: TBadge[];
};

const ProviderLinkList: FC<TProviderLinkListProps> = ({ providersList, label, width, height, spacing }) => {
  return (
    <VStack w="full" align={{ base: "center", lg: "start" }} spacing={0}>
      {label && <Heading as="h4">{label}</Heading>}
      <HStack spacing={spacing} justifyContent={{ base: "center", lg: "start" }} w="full" position="relative" h="full">
        {providersList.map((provider) => {
          return (
            <div key={provider.image.path}>
              {provider.url ? (
                <Link href={provider.url} passHref target={provider.newTab ? "_blank" : "_self"} rel="nofollow">
                  <BadgeImage
                    badge={provider.image}
                    imageProps={{
                      maxW: width,
                      maxH: height,
                    }}
                  />
                </Link>
              ) : (
                <BadgeImage
                  badge={provider.image}
                  imageProps={{
                    maxW: width,
                    maxH: height,
                  }}
                />
              )}
            </div>
          );
        })}
      </HStack>
    </VStack>
  );
};

ProviderLinkList.defaultProps = {
  width: "82px",
  height: "24px",
  spacing: "4",
};

export default ProviderLinkList;
