import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";

import cartReducer from "../features/cart/cartSlice";
import cmsReducer from "../features/cms/cmsSlice";
import configReducer from "../features/config/configSlice";
import userOrderHistoryReducer from "../features/order/orderSlice";
import productsReducer from "../features/products/productsSlice";
import userReducer from "../features/user/userSlice";
import vehiclesReducer from "../features/vehicles/vehiclesSlice";

const makeStore = () => {
  return configureStore({
    reducer: {
      cart: cartReducer,
      config: configReducer,
      cms: cmsReducer,
      user: userReducer,
      products: productsReducer,
      userOrderHistory: userOrderHistoryReducer,
      vehicles: vehiclesReducer,
    },
  });
};

const store = makeStore();

export type TAppState = ReturnType<typeof store.getState>;

export type TAppDispatch = typeof store.dispatch;

export type TAppThunk<ReturnType = void> = ThunkAction<ReturnType, TAppState, unknown, Action<string>>;

export default store;
