import { AxiosPromise } from "axios";

import { API_ROUTES } from "@utils/api-routes";
import api from "@utils/axios/public";

import {
  IOcaList,
  IGetOcasParams,
  IGetVehicleBasesParams,
  IVehicle,
  IGetVehicleByVinParam,
  IVehicleParams,
} from "./types";

const getOcas = ({
  make,
  model,
  startYear,
  endYear,
  vehicleModificationId,
  limit,
  page,
}: IGetOcasParams): AxiosPromise<IOcaList> =>
  api(API_ROUTES.supportedVehicles.getOcas, {
    params: {
      make,
      model,
      startYear,
      endYear,
      vehicleModificationId,
      limit,
      page,
    },
  });

const getVehicleBases = ({ make }: IGetVehicleBasesParams): AxiosPromise<IVehicle[]> =>
  api(API_ROUTES.supportedVehicles.getVehicleBases(make));

const getVehicleByVin = ({ vin }: IGetVehicleByVinParam): AxiosPromise<IVehicleParams> =>
  api(API_ROUTES.supportedVehicles.getVehicleByVin(vin));

export const vehiclesService = {
  getOcas,
  getVehicleBases,
  getVehicleByVin,
};
