import type { ComponentStyleConfig } from "@chakra-ui/theme";

const Tooltip: ComponentStyleConfig = {
  baseStyle: {
    fontSize: "textSize.small",
    bg: "black",
    color: "white",
    borderRadius: "8px",
    padding: "4",
  },
  defaultProps: {},
};

export default Tooltip;
