import queryString from "query-string";

export const stringifyToSearchParams = <T extends Record<string, unknown>>(
  data: T,
  options?: queryString.StringifyOptions & { addQueryPrefix: boolean }
): string => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { addQueryPrefix = true } = options || {};

  return `${addQueryPrefix ? "?" : ""}${queryString.stringify(data, { skipNull: true, ...options })}`;
};
