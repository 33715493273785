import { FC } from "react";
import { useEffectOnce } from "react-use";

import { useAppDispatch } from "@utils/hooks/hooks";
import { setConfig } from "features/config/configSlice";
import { TConfig } from "features/config/types";

type TConfigProps = {
  config: TConfig;
};

const ConfigProvider: FC<TConfigProps> = ({ config }) => {
  const dispatch = useAppDispatch();

  useEffectOnce(() => {
    dispatch(setConfig({ config }));
  });

  return null;
};

export default ConfigProvider;
