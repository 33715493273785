/* eslint-disable @typescript-eslint/naming-convention */
import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { getSession } from "next-auth/react";

import { getUserLocalization } from "@modules/Localization";
import { getXLocalizationHeader } from "@modules/Localization/utils";

import { useUnauthorized } from "./axios/common";
import { logger } from "./logger/client";

const BASE_URL = `${process.env.NEXT_PUBLIC_CLIENT_BACK_END_URL}/shop-api`;
const X_AUTH_KEY = process.env.NEXT_PUBLIC_BACK_END_X_AUTH_KEY;

interface IApiInstance extends AxiosInstance {
  request<T = any>(config: AxiosRequestConfig): Promise<T>;
  get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
  delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
  head<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
  post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
  put<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
  patch<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
}

const createAxiosInstance = (): IApiInstance => {
  const instance = axios.create({ baseURL: BASE_URL }) as IApiInstance;

  instance.interceptors.request.use(async (request) => {
    logger.debug(`API request (axiosInterceptor): ${request.url}`);

    const localization = getUserLocalization();
    const localizationString = getXLocalizationHeader(localization);

    const session = await getSession();

    request.headers = {
      ...request.headers,
      ...(localizationString ? { "X-Localization": localizationString } : {}),
      ...(X_AUTH_KEY ? { "X-Auth-Key": X_AUTH_KEY } : {}),
      ...(session?.accessToken
        ? {
            Authorization: `Bearer ${session.accessToken}`,
          }
        : {}),
    };

    logger.debug(`API request (axiosInterceptor: headers): ${JSON.stringify(request.headers)}`);

    return request;
  });

  // Never do this, now it's done, so not going to fix this.
  instance.interceptors.response.use((response) => {
    return response.data;
  }, useUnauthorized);

  return instance;
};

/**
 * @deprecated - because of stupid interceptor implementation; Instead use `@utils/axios`;
 */
const api: IApiInstance = createAxiosInstance();

export default api;
