import { AxiosPromise } from "axios";

import { TUserAdress } from "@modules/AddressForm/types";
import { API_ROUTES } from "@utils/api-routes";
import api from "@utils/axios";

const getAddresses = (): AxiosPromise<TUserAdress[]> => api.get(API_ROUTES.userAddress.getAddresses);

const deleteAddress = (addressId: number): AxiosPromise<TUserAdress[]> =>
  api.delete(API_ROUTES.userAddress.getAddress(`${addressId}`));

const makeDefaultAddress = (addressId: number): AxiosPromise<TUserAdress[]> =>
  api.patch(API_ROUTES.userAddress.setDefaultAddress(`${addressId}`), {});

const userService = {
  getAddresses,
  deleteAddress,
  makeDefaultAddress,
};

export default userService;
