import React, { FC, useState } from "react";

import { Box, Flex, Input, Button, InputGroup, FormErrorMessage, FormControl, VStack } from "@chakra-ui/react";
import { useFormik } from "formik";
import useTranslation from "next-translate/useTranslation";
import * as Yup from "yup";

import LoaderWrapper from "@components/common/LoaderWrapper/LoaderWrapper";
import { useAppToast } from "@components/Toast/hooks/UseAppToast";
import { API_ROUTES } from "@utils/api-routes";
import api from "@utils/axios/public";

const SubscribeInput: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const toast = useAppToast();
  const { t } = useTranslation();

  const submitEmail = async (email: string) => {
    setIsLoading(true);

    const result = await api
      .post(API_ROUTES.braze.subscribeToNewsletter, {
        email,
      })
      .catch(() => {
        toast.error({ description: t("error:something-went-wrong-toast") });
      });

    if (result && result.status === 200) {
      toast.success({ description: t("common:subscribed-to-newsletter") });
      setIsHidden(true);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("error:email-not-valid").required("error:email-is-required"),
    }),
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: (values: { email: string }) => {
      submitEmail(values.email).then(() => {
        setIsLoading(false);
      });
    },
  });

  return (
    <Box w="full">
      {!isHidden ? (
        <LoaderWrapper isLoading={isLoading}>
          <form onSubmit={formik.handleSubmit}>
            <FormControl isInvalid={!!formik.errors.email}>
              <InputGroup>
                <Flex
                  direction={{ base: "column", md: "row" }}
                  alignItems="center"
                  justifyContent="center"
                  w={{ base: "600px", lg: "full" }}
                  maxW="container.xl"
                  mx="auto"
                  p="1"
                  mb="2"
                >
                  <Input
                    id="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    mb={{ base: 2, md: 0 }}
                    variant="filled"
                    placeholder="Enter your email"
                    color={formik.values.email.length ? "black" : "gray.200"}
                    fontSize="1rem"
                    w={{ base: "full", md: "67%" }}
                    h="14"
                  />
                  <Button type="submit" w={{ base: "full", md: "33%" }} ml={{ base: 0, md: 2 }} h="14">
                    {t("common:subscribe")}
                  </Button>
                </Flex>
              </InputGroup>
              {formik.touched.email && formik.errors.email ? (
                <FormErrorMessage ml="2">{formik.errors.email}</FormErrorMessage>
              ) : null}
            </FormControl>
          </form>
        </LoaderWrapper>
      ) : (
        <LoaderWrapper isLoading={isLoading}>
          <VStack alignItems="center" fontSize="textSize.title" padding="12px" lineHeight="28px">
            <h2>{t("common:subscribed-to-newsletter")}</h2>
          </VStack>
        </LoaderWrapper>
      )}
    </Box>
  );
};

export default SubscribeInput;
