import { FC } from "react";

import { VStack, HStack, Text, StackDivider } from "@chakra-ui/react";
import useTranslation from "next-translate/useTranslation";
import Image from "next/legacy/image";

import { useAppSelector } from "@utils/hooks/hooks";
import { scrollbarStyles } from "@utils/scrollbarUtils";
import { selectCart } from "features/cart/cartSlice";

import CartPopupProductsItem from "./CartPopupProductsItem";
import { PriceTag } from "./PriceTag";

const CartPopupProducts: FC = () => {
  const { t } = useTranslation();
  const cart = useAppSelector(selectCart);

  return (
    <VStack w="full">
      <VStack
        data-test-id="cartPopoverProductList"
        w="full"
        px="6"
        spacing="0"
        bg="gray.50"
        maxH="290px"
        overflowY="auto"
        overflowX="hidden"
        sx={scrollbarStyles}
        divider={<StackDivider />}
      >
        {cart.cart?.items && cart.cart.items.length ? (
          cart.cart?.items.map((item) => <CartPopupProductsItem key={item.id} cartItem={item} />)
        ) : (
          <VStack py="6">
            <Image src="/assets/icons/empty-cart.svg" alt="" height={32} width={32} />
            <Text fontWeight="bold">{t("cart:empty-cart")}</Text>
          </VStack>
        )}
      </VStack>
      <HStack data-test-id="subtotalBlock" px="6" pt="6" w="full" justify="space-between">
        <Text data-test-id="cartPopupSubtotalText"> {t("cart:subtotal")}</Text>
        <PriceTag
          data-test-id="cartPopupSubtotalPriceTag"
          currentPrice={cart.cart?.totals.items || 0}
          currency={cart.cart?.currency}
          priceProps={{ fontWeight: "bold" }}
        />
      </HStack>
    </VStack>
  );
};

export default CartPopupProducts;
