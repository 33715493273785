import { FC } from "react";
import { IconType } from "react-icons";

import { Alert, AlertTitle, AlertDescription, CloseButton, Stack } from "@chakra-ui/react";
import { IoIosCheckmarkCircle, IoIosAlert, IoMdInformationCircleOutline } from "react-icons/io";
import { MdClose } from "react-icons/md";

type TToastType = "success" | "error" | "info" | "warning";

export interface IToastProps {
  /**
   * @defaultValue "success"
   */
  type?: TToastType;
  title?: string;
  description?: string;
  onClose: () => void;
}

export const Toast: FC<IToastProps> = ({ type = "success", title, description, onClose }) => {
  const typeToVariantAndIcon: Record<
    TToastType,
    {
      variant: string;
      icon: IconType;
    }
  > = {
    error: {
      variant: "toastError",
      icon: IoIosAlert,
    },
    success: {
      variant: "toastSuccess",
      icon: IoIosCheckmarkCircle,
    },
    info: {
      variant: "toastInfo",
      icon: IoMdInformationCircleOutline,
    },
    warning: {
      variant: "toastWarning",
      icon: IoIosAlert,
    },
  };

  const { icon: Icon, variant } = typeToVariantAndIcon[type];

  return (
    <Alert variant={variant} padding={3} maxW="320px" display="flex" alignItems="center" border="none">
      <Stack direction="row" alignItems="start" flex={1}>
        <Icon fontSize="20px" />
        <Stack spacing={0} flex={1}>
          {title && <AlertTitle fontSize="textSize.bodyText">{title}</AlertTitle>}
          {description && <AlertDescription fontSize="textSize.bodyText">{description}</AlertDescription>}
        </Stack>
      </Stack>
      <CloseButton w="24px" h="24px" alignSelf="flex-start" position="relative" right={-1} top={-1} onClick={onClose}>
        <MdClose />
      </CloseButton>
    </Alert>
  );
};
