import { FC } from "react";

import { chakra, ImageProps, Box } from "@chakra-ui/react";

import Image from "@components/Image";

const toCorrectSize = (
  size: string | number | undefined | ImageProps["width"],
  max: string | undefined
): string | undefined => {
  if (!size) {
    return undefined;
  }

  if (typeof size === "string" && size.indexOf("%") > -1) {
    return size;
  }

  if (max && parseInt(size as string, 10) > parseInt(max, 10)) {
    return `${max}px`;
  }

  if (typeof size === "number") {
    return `${size}px`;
  }

  return size as string;
};

const BadgeImage: FC<{
  badge: TBadgeImage;
  imageProps?: ImageProps;
}> = ({ badge, imageProps }) => {
  const width = toCorrectSize(imageProps?.w || badge.width || 56, imageProps?.maxW as string);
  const height = toCorrectSize(imageProps?.h || badge.height || 56, imageProps?.maxH as string);

  if (badge.path.includes("svg")) {
    return (
      <Box position="relative">
        <chakra.embed
          {...imageProps}
          src={badge.path}
          width={{ base: width ?? "100%", md: width }}
          height={{ base: height ?? "100%", md: height }}
        />
        <Box position="absolute" top={0} right={0} bottom={0} left={0} zIndex={1} />
      </Box>
    );
  }

  return (
    <Image
      {...imageProps}
      src={badge.path}
      width={width || imageProps?.maxW}
      height={height || imageProps?.maxH}
      alt=""
    />
  );
};

export default BadgeImage;
