import type { ChangeEvent, DependencyList, EffectCallback } from "react";
import { useEffect, useRef, useState } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { useCookie } from "react-use";

import { useBreakpointValue } from "@chakra-ui/react";
import { AxiosError } from "axios";
import Cookie from "js-cookie";
import { Session } from "next-auth";
import { signIn, signOut, useSession } from "next-auth/react";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";

import { removeCheckoutStep } from "@components/checkout/utils/utils";
import { useAppToast } from "@components/Toast/hooks/UseAppToast";
import { ICarBrand } from "@features/types";
import { getAxiosErrorResponse } from "@utils/axios/utils";
import { QUERY_PARAM, COOKIE_NAME, DEFAULT_LANGUAGE, TRANSLATION_DOMAIN } from "@utils/constants";
import { stringifyToSearchParams } from "@utils/query-utils";
import { IServerValidationError, IServerValidationErrors } from "@utils/types";
import { getSignInCallbackUrl, getSignOutCallbackUrl, isEmpty } from "@utils/utils";

import type { TAppDispatch, TAppState } from "../../store/store";

export const useForm =
  <TContent>(defaultValues: TContent) =>
  (handler: (content: TContent) => void) =>
  async (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.persist();

    const form = event.target as HTMLFormElement;
    const elements = Array.from(form.elements) as HTMLInputElement[];
    const data = elements
      .filter((element) => element.hasAttribute("name"))
      .reduce(
        (object, element) => ({
          ...object,
          [`${element.getAttribute("name")}`]: element.value,
        }),
        defaultValues
      );
    await handler(data);
    form.reset();
  };

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/ban-types
export const useInterval = (callback: Function, delay: number) => {
  // eslint-disable-next-line @typescript-eslint/ban-types
  const savedCallback = useRef<Function>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const handler = (...args: any) => savedCallback.current?.(...args);

    if (delay !== null) {
      const id = setInterval(handler, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

export const useAppDispatch = () => useDispatch<TAppDispatch>();

export const useAppSelector: TypedUseSelectorHook<TAppState> = useSelector;

export const useIsDesktop = (): boolean | undefined => useBreakpointValue({ base: false, lg: true }, { ssr: true });
export const useIsMobile = (): boolean | undefined => useBreakpointValue({ base: true, md: false }, { ssr: true });

export type TVehicleCookie = Pick<ICarBrand, "code" | "voltasMappingName">;

interface ICarBrandHelpers {
  selectedCarBrand: TVehicleCookie | undefined;
  selectCarBrand: (
    brand: ICarBrand | undefined,
    dataKey: keyof TVehicleCookie,
    options?: {
      shallow?: boolean;
    }
  ) => void;
}

export const useCarBrandSelect = (): ICarBrandHelpers => {
  const router = useRouter();
  const [selectedCarBrand, setVehicleCookie, deleteCookie] = useCookie(COOKIE_NAME.VEHICLE);
  const parsedVehicleCookie = selectedCarBrand ? (JSON.parse(selectedCarBrand) as TVehicleCookie) : undefined;

  /**
   * This function has to just select/deselect the car brand.
   * If we have same brand as selected we deselect it.
   * If we have different brand we select it.
   * If we have no brand we select it.
   */
  const selectCarBrand = (
    brand: ICarBrand | undefined,
    dataKey: keyof TVehicleCookie,
    options: {
      shallow?: boolean;
    } = {
      shallow: true,
    }
  ) => {
    const currentRouterParams = {
      pathname: router.asPath,
      query: { carBrand: router.query.carBrand },
    };

    if (brand && brand.voltasMappingName !== parsedVehicleCookie?.voltasMappingName) {
      setVehicleCookie(
        JSON.stringify({ code: brand.code, voltasMappingName: brand.voltasMappingName } as TVehicleCookie),
        {
          httpOnly: false,
        }
      );
      currentRouterParams.query[QUERY_PARAM.CAR_BRAND] = brand[dataKey];
    }

    if (!brand || (brand && brand.voltasMappingName === parsedVehicleCookie?.voltasMappingName)) {
      deleteCookie();
      delete currentRouterParams.query[QUERY_PARAM.CAR_BRAND];
    }

    const url = new URL(router.asPath, process.env.NEXT_PUBLIC_BACK_END_URL);
    const asPath = `${url.pathname}${stringifyToSearchParams(currentRouterParams.query)}`;

    router.push(asPath, undefined, { shallow: options.shallow });
  };

  return { selectedCarBrand: parsedVehicleCookie, selectCarBrand };
};

/**
 * Handles logged in user cart after session over/sign out flow.
 *
 */
export const removeLoggedUserCart = (): void => {
  // Remove users cart for fresh guest cart.
  Cookie.remove(COOKIE_NAME.OBD_CART);
  // Clear current checkout step.
  removeCheckoutStep();
};

// NOTE: TO be used later on
export const useAuth = (shouldRedirect: boolean): boolean => {
  const { data: session } = useSession();
  const router = useRouter();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    if (session?.error === "RefreshAccessTokenError") {
      signOut({ callbackUrl: "/", redirect: shouldRedirect });
      removeLoggedUserCart();
    }

    if (session === null) {
      if (router.route !== "/login") {
        router.replace("/login");
      }

      removeLoggedUserCart();
      setIsAuthenticated(false);
    } else if (session !== undefined) {
      if (router.route === "/login") {
        router.replace("/");
      }
      setIsAuthenticated(true);
    }
  }, [session]);

  return isAuthenticated;
};

interface ISessionUtils {
  isLoggedIn?: boolean;
  session: Session | null;
  signIn: (callbackUrl?: string) => void;
  signOut: (callbackUrl?: string) => void;
  sessionUpdate: (data?: any) => Promise<Session | null>;
}

export const useSessionUtils = (): ISessionUtils => {
  const { data: session, status, update } = useSession();
  const router = useRouter();
  const routerPath = router.locale === DEFAULT_LANGUAGE ? router.asPath : `/${router.locale}${router.asPath}`;

  const handleSignOut = async (callbackUrl?: string) => {
    const signOutCallbackUrl = getSignOutCallbackUrl(routerPath, callbackUrl, router.locale);

    await signOut({ callbackUrl: signOutCallbackUrl });
    removeLoggedUserCart();
  };

  const handleSignIn = async (callbackUrl?: string) => {
    const signInCallbackUrl = getSignInCallbackUrl(routerPath, callbackUrl, router.locale);

    await signIn("obdeleven", { callbackUrl: signInCallbackUrl });
  };

  return {
    isLoggedIn: status === "authenticated",
    session,
    signIn: handleSignIn,
    signOut: handleSignOut,
    sessionUpdate: update,
  };
};

interface ITranslatedServerValidationErrors {
  [key: string]: string;
}

interface IServerErrorUtils {
  validationErrors: IServerValidationErrors | undefined;
  generalError: string | undefined;
  setServerError: (error: AxiosError, toastIfNotFieldRelated?: boolean) => undefined;
  getTranslatedServerValidationErrors: (
    error: AxiosError,
    toastIfNotFieldRelated?: boolean
  ) => ITranslatedServerValidationErrors;
  clearErrors: () => void;
  toastServerError: (error: AxiosError) => undefined;
  toastCustomError: (error: string) => void;
  getFieldErrorTranslationKey: (fieldKey: string) => string | undefined;
  getFieldErrorTranslation: (fieldKey: string) => string | undefined;
}

export const useServerErrorUtils = (formFieldKeys: string[] = []): IServerErrorUtils => {
  const toast = useAppToast();
  const { t } = useTranslation();
  const [validationErrors, setValidationErrors] = useState<IServerValidationErrors | undefined>(undefined);
  const [generalError, setGeneralError] = useState<string | undefined>(undefined);

  const getFormattedErrorTranslationKey = (serverKey: string) => `${TRANSLATION_DOMAIN.VALIDATION_ERROR}:${serverKey}`;
  const getErrorTranslation = (error: IServerValidationError) =>
    t(getFormattedErrorTranslationKey(error?.key), error?.params);

  const getFieldErrorTranslationKey = (fieldKey: string): string | undefined => {
    const key = validationErrors?.[fieldKey]?.key;
    return key ? getFormattedErrorTranslationKey(key) : undefined;
  };

  const getFieldErrorTranslation = (fieldKey: string): string | undefined => {
    const error = validationErrors?.[fieldKey];
    return error ? getErrorTranslation(error) : undefined;
  };

  const getFirstValidationError = (errors: IServerValidationErrors): IServerValidationError => {
    return Object.values(errors || {})?.[0];
  };

  const containsFieldRelatedErrors = (errors: IServerValidationErrors): boolean => {
    if (!isEmpty(formFieldKeys)) {
      const errorKeys = Object.keys(errors || {});
      return formFieldKeys.some((key) => errorKeys.includes(key));
    }

    return false;
  };

  const setServerError = (error: AxiosError, toastIfNotFieldRelated = true): undefined => {
    const { errors, message } = getAxiosErrorResponse(error);

    if (message) {
      setGeneralError(message);
    }

    if (errors) {
      setValidationErrors(errors);

      if (!containsFieldRelatedErrors(errors) && toastIfNotFieldRelated) {
        toast.error({ description: getErrorTranslation(getFirstValidationError(errors)) });
      }
    } else if (toastIfNotFieldRelated) {
      toast.error({ description: message || t("common:unknown-error-occurred") });
    }

    return undefined;
  };

  const getTranslatedServerValidationErrors = (
    error: AxiosError,
    toastIfNotFieldRelated = true
  ): ITranslatedServerValidationErrors => {
    const { errors, message } = getAxiosErrorResponse(error);

    if (errors) {
      if (!containsFieldRelatedErrors(errors) && toastIfNotFieldRelated) {
        toast.error({ description: getErrorTranslation(getFirstValidationError(errors)) });
      }
    } else if (toastIfNotFieldRelated) {
      toast.error({ description: message || t("common:unknown-error-occurred") });
    }

    return Object.keys(errors || {}).reduce((acc, k) => {
      acc[k] = errors?.[k] && getErrorTranslation(errors[k]);
      return acc;
    }, {});
  };

  const clearErrors = () => {
    setGeneralError(undefined);
    setValidationErrors(undefined);
  };

  const toastServerError = (error: AxiosError) => {
    const { message, errors } = getAxiosErrorResponse(error) || {};

    if (errors) {
      toast.error({ description: getErrorTranslation(getFirstValidationError(errors)) });
    } else {
      toast.error({ description: message || t("common:unknown-error-occurred") });
    }

    return undefined;
  };

  const toastCustomError = (message: string) => {
    toast.error({ description: message || t("common:unknown-error-occurred") });
  };

  return {
    validationErrors,
    generalError,
    setServerError,
    getTranslatedServerValidationErrors,
    clearErrors,
    toastServerError,
    toastCustomError,
    getFieldErrorTranslationKey,
    getFieldErrorTranslation,
  };
};

const isDevelopmentRun = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

const useLegacyEffect = (cb: EffectCallback, deps: DependencyList | undefined) => {
  const isMountedRef = useRef(!isDevelopmentRun);

  useEffect(() => {
    if (!isMountedRef.current) {
      isMountedRef.current = true;
      return undefined;
    }

    return cb();
  }, deps);
};
/**
 *
 * @deprecated use instead react-use useEffectOnce
 */
export const useMount = (cb: EffectCallback) => useLegacyEffect(cb, []);
export const useUnMount = (cb: ReturnType<EffectCallback>) =>
  useLegacyEffect(() => {
    return cb;
  }, []);
