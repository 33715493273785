import { AxiosError } from "axios";
import Router from "next/router";

import { APP_ROUTES } from "@utils/app-routes";
import { logger } from "@utils/logger/server";

export const useUnauthorized = (error: AxiosError): Promise<AxiosError> => {
  logger.debug("useUnauthorized", error);

  if (error?.response?.status === 401) {
    Router.push(APP_ROUTES.signOut);
  }

  return Promise.reject(error);
};
