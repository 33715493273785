export type TPageLayout = {
  hideHeader: boolean;
  hideFooter: boolean;
  stickyPayment: boolean;
};

const webViewPageLayout = {
  hideHeader: true,
  hideFooter: true,
  stickyPayment: true,
};

export function getWebViewPageLayout(): TPageLayout {
  return webViewPageLayout;
}
