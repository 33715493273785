import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { IOcaList, IVehicle, IGetVehicleBasesParams, IGetOcasParams, IGetVehicleByVinParam } from "./types";
import { vehiclesService } from "./vehiclesActions";

interface IVehiclesState {
  ocas: IOcaList | null;
  vehicleBases: IVehicle[] | null;
}

const initialState: IVehiclesState = {
  ocas: null,
  vehicleBases: null,
};

export const getOcas = createAsyncThunk("vehicles/getOcas", async (params: IGetOcasParams, thunkAPI) => {
  try {
    const { data } = await vehiclesService.getOcas(params);
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getVehicleBases = createAsyncThunk(
  "vehicles/getVehicleBases",
  async (params: IGetVehicleBasesParams, thunkAPI) => {
    try {
      const { data } = await vehiclesService.getVehicleBases(params);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getVehicleByVin = createAsyncThunk(
  "vehicles/getVehicleByVin",
  async (params: IGetVehicleByVinParam, thunkAPI) => {
    try {
      const { data } = await vehiclesService.getVehicleByVin(params);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const vehiclesSlice = createSlice({
  name: "vehicles",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOcas.pending, (state, action) => {
        if ((action.meta.arg.page || 1) === 1) {
          state.ocas = null;
        }
      })
      .addCase(getOcas.fulfilled, (state, action) => {
        if (action.payload.page !== 1 && state.ocas) {
          action.payload.items = state.ocas.items.concat(action.payload.items);
        }
        state.ocas = action.payload;
      })
      .addCase(getVehicleBases.fulfilled, (state, action) => {
        state.vehicleBases = action.payload;
      });
  },
});

export default vehiclesSlice.reducer;
