import { createContext, FC, PropsWithChildren, useContext, useMemo } from "react";

import { TCountry } from "@utils/types";

const Context = createContext<TCountry[]>([]);

interface IStaticPathContextProps {
  countries?: TCountry[];
}

export const CountryListContext: FC<PropsWithChildren<IStaticPathContextProps>> = ({ children, countries }) => {
  const state = useMemo(() => countries || [], [countries]);

  return <Context.Provider value={state}>{children}</Context.Provider>;
};

export const useCountryList = (): TCountry[] => useContext(Context);
