import type { ComponentStyleConfig } from "@chakra-ui/theme";

const Input: ComponentStyleConfig = {
  baseStyle: {},
  variants: {
    filled: {
      field: {
        bg: "gray.50",
        _focus: {
          bg: "gray.50",
        },
        _hover: {
          bg: "gray.100",
        },
        borderRadius: "8px",
        borderWidth: "2px",
      },
    },
  },
  defaultProps: {
    size: "lg",
  },
};

export default Input;
