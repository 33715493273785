import { FC, Fragment, useState } from "react";

import { Box, VStack, Text, Link as ChakraLink } from "@chakra-ui/react";
import useTranslation from "next-translate/useTranslation";
import { isAbsoluteUrl } from "next/dist/shared/lib/utils";
import Link from "next/link";
import { useRouter } from "next/router";
import { FaChevronRight } from "react-icons/fa";

import { CURRENCY } from "@configs/currencies";
import { DEFAULT_LOCALE } from "@configs/translations";
import { useAppStaticPath } from "@context/AppStaticPathContext";
import { useUserLocalization } from "@modules/Localization";
import { useSessionUtils } from "@utils/hooks/hooks";
import { scrollbarStyles } from "@utils/scrollbarUtils";
import { getLanguageByLocaleCode } from "@utils/translations";
import { convertUrlToPath } from "@utils/utils";

import ProfileIcon from "../../../assets/icons/Account.svg";
import NavigationItem from "./NavigationItem";
import NavigationItemBack from "./NavigationItemBack";

type TMobileNavigationProps = {
  navLinks: TNavigationLink[];
  languages: TLanguage[];
  userNavLinks: TNavigationLink[];
};

type TActiveContent = "main" | "currencySelector" | "languageSelector" | "accountNavigation";

const MobileNavigation: FC<TMobileNavigationProps> = ({ navLinks, languages, userNavLinks }) => {
  const { isLoggedIn, signIn, signOut } = useSessionUtils();
  const { asPath, locale } = useRouter();
  const [activeContent, setActiveContent] = useState<TActiveContent>("main");

  const { currency: selectedCurrency, setCurrency } = useUserLocalization();

  const { accountPath } = useAppStaticPath();
  const { t } = useTranslation();

  const handleCurrencyChange = (value) => {
    setCurrency(value);
  };

  const localeCode = locale !== DEFAULT_LOCALE ? `/${locale}` : "";

  const generateNavigation = (navItems) => {
    return navItems.map((link) => {
      const { title, url } = link;
      const href = isAbsoluteUrl(url) ? url : `${localeCode}${convertUrlToPath(url)}`;
      // Should be replaced with next/link after it works with car brand selector functionality
      return (
        <Fragment key={link.code}>
          <ChakraLink
            w="full"
            href={href}
            target={link.newTab ? "_blank" : "_self"}
            _hover={{ textDecoration: "none" }}
          >
            <NavigationItem value={title} />
          </ChakraLink>
        </Fragment>
      );
    });
  };

  const main = (
    <>
      <VStack mb="12">{generateNavigation(navLinks)}</VStack>
      <VStack w="full" mb="12">
        <NavigationItem
          value={selectedCurrency}
          onClick={() => setActiveContent("currencySelector")}
          rightIcon={<FaChevronRight size={12} />}
        />

        <NavigationItem
          value={
            languages.find((language) => getLanguageByLocaleCode(language.code) === locale)?.nativeLanguageName || ""
          }
          onClick={() => setActiveContent("languageSelector")}
          rightIcon={<FaChevronRight size={12} />}
        />
      </VStack>

      <NavigationItem
        value={t("navigation:account")}
        onClick={() => setActiveContent("accountNavigation")}
        leftIcon={<ProfileIcon fill="white" />}
        rightIcon={<FaChevronRight size={12} />}
      />
    </>
  );

  const backNavigation = <NavigationItemBack onClick={() => setActiveContent("main")} />;

  const languageSelector = (
    <>
      {backNavigation}
      <VStack mt="16">
        {languages.map((lang) => {
          const languageCode = getLanguageByLocaleCode(lang.code);

          return (
            <ChakraLink
              w="full"
              _hover={{
                textDecoration: "none",
              }}
              key={lang.code}
              href={`/${languageCode}${asPath}${languageCode === DEFAULT_LOCALE && asPath === "/" ? "?fl" : ""}`}
            >
              <NavigationItem key={lang.code} value={lang.nativeLanguageName} isActive={languageCode === locale} />
            </ChakraLink>
          );
        })}
      </VStack>
    </>
  );

  const currencySelector = (
    <>
      {backNavigation}
      <VStack mt="16">
        {Object.values(CURRENCY).map((currency) => (
          <NavigationItem
            key={currency}
            value={currency}
            isActive={currency === selectedCurrency}
            onClick={() => handleCurrencyChange(currency)}
          />
        ))}
      </VStack>
    </>
  );

  const accountNavigation = (
    <>
      {backNavigation}
      <VStack mt="16">
        {!isLoggedIn ? (
          <>
            <NavigationItem value={t("navigation:login")} onClick={() => signIn()} />
            {generateNavigation(userNavLinks)}
          </>
        ) : (
          <>
            <ChakraLink as={Link} href={accountPath} w="full">
              <NavigationItem value={t("navigation:account")} />
            </ChakraLink>
            {generateNavigation(userNavLinks)}
            <NavigationItem value={t("navigation:logout")} onClick={() => signOut()} />
          </>
        )}
      </VStack>
    </>
  );

  const renderActiveContent = () => {
    switch (activeContent) {
      case "main":
        return main;
      case "languageSelector":
        return languageSelector;
      case "currencySelector":
        return currencySelector;
      case "accountNavigation":
        return accountNavigation;
      default:
        return <Text>Something Went wrong</Text>;
    }
  };

  return (
    <Box
      as="nav"
      px={6}
      pt={16}
      pb="200px"
      display={{ lg: "none" }}
      bg="black"
      top="70px"
      h="100vh"
      w="255px"
      overflowY="auto"
      sx={scrollbarStyles}
    >
      {renderActiveContent()}
    </Box>
  );
};

export default MobileNavigation;
