export const styles = {
  global: {
    "ol,ul": {
      margin: "auto",
      padding: 0,
      paddingInlineStart: "40px",
    },
  },
};

export default styles;
