export enum ProductPageField {
  QUANTITY = "quantity",
  REDEEM_QUANTITY = "redeemQuantity",
}

export enum CartItemField {
  QUANTITY = "quantity",
  REDEEM_QUANTITY = "redeemQuantity",
}

export enum OrderSummaryField {
  COUPON = "coupon",
}

export enum VehicleSearchByVinField {
  VIN = "vin",
}
