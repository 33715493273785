import { Stack, StackProps, Text, TextProps } from "@chakra-ui/react";

import { formatPrice } from "@utils/helpers/helpers";

type TPriceTagProps = {
  currency?: string;
  salePriceDirection?: "row" | "column";
  rootProps?: StackProps;
  priceProps?: TextProps;
  salePriceProps?: TextProps;
  currentPrice: number;
  originalPrice?: number;
};

type TPriceProps = {
  children?: React.ReactNode;
  isOnSale?: boolean;
  textProps?: TextProps;
};

const Price = (props: TPriceProps) => {
  const { isOnSale, children, textProps } = props;
  const defaultColor = "blackAlpha.600";
  const onSaleColor = "gray.500";
  const color = isOnSale ? onSaleColor : defaultColor;

  const defaultSize = "textSize.regular";
  const onSaleSize = "textSize.labels";
  const size = isOnSale ? onSaleSize : defaultSize;
  return (
    <Text
      data-test-id="priceBeforeSale"
      as="span"
      color={color}
      fontSize={size}
      textDecoration={isOnSale ? "line-through" : "none"}
      {...textProps}
    >
      {children}
    </Text>
  );
};

const SalePrice = (props: TextProps) => <Text as="span" fontSize="textSize.regular" {...props} />;

export const PriceTag = (props: TPriceTagProps) => {
  const { currency, rootProps, priceProps, salePriceProps, salePriceDirection, currentPrice, originalPrice } = props;
  return (
    <Stack direction={salePriceDirection} spacing="1" {...rootProps}>
      <Price isOnSale={!!originalPrice} textProps={priceProps}>
        {formatPrice(originalPrice ?? currentPrice, { currency })}
      </Price>
      {originalPrice && (
        <SalePrice data-test-id="currentPrice" {...salePriceProps}>
          {formatPrice(currentPrice, { currency })}
        </SalePrice>
      )}
    </Stack>
  );
};

PriceTag.defaultProps = {
  salePriceDirection: "column",
};
