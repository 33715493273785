import { breadcrumbAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/styled-system";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseStyleList = defineStyle({
  paddingInlineStart: 0,
  paddingLeft: 0,
  display: "flex",
  flexWrap: "wrap",
  maxWidth: "100%",
});

const baseStyleLink = defineStyle({
  color: "common.text",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 590,
  lineHeight: "20px",
  whiteSpace: "nowrap",
  maxWidth: ["calc(100vw - 32px)", "calc(100vw - 32px)", "calc(100vw - 360px)"],
  overflow: "hidden",
  textOverflow: "ellipsis",
  _hover: {
    textDecoration: "none",
  },
});

const baseStyleSeparator = defineStyle({
  mx: 2,
  color: "primary.500",
});

const baseStyle = definePartsStyle({
  list: baseStyleList,
  link: baseStyleLink,
  separator: baseStyleSeparator,
});

const breadcrumbTheme = defineMultiStyleConfig({ baseStyle });

export default breadcrumbTheme;
