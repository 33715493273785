import { FC, ReactNode, useContext } from "react";
import { IntercomProvider } from "react-use-intercom";

import { Box, useToken } from "@chakra-ui/react";
import useTranslation from "next-translate/useTranslation";
import dynamic from "next/dynamic";
import NextNProgress from "nextjs-progressbar";

import Loader from "@components/widgets/Loader";
import { LoadingContext } from "@context/LoadingProvider";
import { IAppData } from "@middlewares/globalData";
import { useAppDispatch, useMount } from "@utils/hooks/hooks";
import { useSessionWatch } from "@utils/hooks/useSessionWatch";
import { getCart } from "features/cart/cartSlice";

import Footer from "./Footer";
import Navigation from "./Navigation/Navigation";

const CartMergeToast = dynamic(() => import("@components/CartMergeToast"), {
  ssr: false,
});

type TLayoutProps = IAppData & {
  children: ReactNode;
};

const Layout: FC<TLayoutProps> = ({
  children,
  footerNavLinks,
  headerNavLinks,
  socialLinks,
  appProviders,
  paymentProviders,
  languages,
  userNavLinks,
  affiliatesBlock,
  config,
  pageType,
  hideHeader,
  hideFooter,
}) => {
  const dispatch = useAppDispatch();
  const { isLoading } = useContext(LoadingContext);
  const { t } = useTranslation();

  useSessionWatch();

  useMount(() => {
    dispatch(getCart());
  });

  const [primary500] = useToken("colors", ["primary.500"]);

  return (
    <>
      {isLoading && <Loader message={t("common:processing-payment")} />}

      <NextNProgress
        options={{
          showSpinner: false,
        }}
        color={primary500}
        startPosition={0.3}
        height={2}
      />

      <CartMergeToast />
      {!hideHeader && (
        <Navigation
          headerNavLinks={headerNavLinks}
          languages={languages}
          userNavLinks={userNavLinks}
          pageType={pageType}
        />
      )}

      {!hideFooter ? (
        <IntercomProvider appId={config.intercom_secret_code} autoBoot={process.env.NODE_ENV === "production"}>
          <Box as="main" pt={{ base: hideHeader ? 0 : "56px", md: hideHeader ? 0 : 20 }} flex={1}>
            {children}
          </Box>
        </IntercomProvider>
      ) : (
        <Box as="main" pt={{ base: hideHeader ? 0 : "56px", md: hideHeader ? 0 : 20 }} flex={1}>
          {children}
        </Box>
      )}

      {!hideFooter && (
        <Footer
          footerNavLinks={footerNavLinks}
          socialLinks={socialLinks}
          appProviders={appProviders}
          paymentProviders={paymentProviders}
          affiliatesBlock={affiliatesBlock}
          config={config}
          pageType={pageType}
        />
      )}
    </>
  );
};

export default Layout;
