import { FC } from "react";

import { ChevronDownIcon } from "@chakra-ui/icons";
import { Menu, MenuList, MenuItem, MenuButton, Text, HStack, Box, Link } from "@chakra-ui/react";
import { useRouter } from "next/router";

import { DEFAULT_LOCALE } from "@configs/translations";
import { getLanguageByLocaleCode } from "@utils/translations";

import CountryFlag from "./inputs/CountrySelect/CountryFlag";

type TLanguageDropdownProps = {
  languages: TLanguage[];
};

const LanguageDropdown: FC<TLanguageDropdownProps> = ({ languages }) => {
  const { locale, asPath } = useRouter();

  return (
    <Box data-test-id="languageDropdown">
      <Menu isLazy>
        <MenuButton fontWeight="medium">
          <HStack>
            <Text>
              {languages.find((language) => getLanguageByLocaleCode(language.code) === locale)?.abbreviation || locale}
            </Text>
            <ChevronDownIcon />
          </HStack>
        </MenuButton>
        <MenuList bg="black" border="none" borderRadius="0" px="0">
          {languages.map((lang) => {
            const languageCode = getLanguageByLocaleCode(lang.code);

            return (
              <Link
                _hover={{
                  textDecoration: "none",
                }}
                key={lang.code}
                href={`/${languageCode}${asPath}${languageCode === DEFAULT_LOCALE && asPath === "/" ? "?fl" : ""}`}
              >
                <MenuItem px="4" py="0">
                  <HStack borderBottom="1px solid" borderColor="whiteAlpha.200" w="full" py="2">
                    <CountryFlag countryCode={lang.code.slice(-2)} round />
                    <Text color={languageCode === locale ? "white" : "whiteAlpha.600"}>
                      {lang.nativeLanguageName || lang.code}
                    </Text>
                  </HStack>
                </MenuItem>
              </Link>
            );
          })}
        </MenuList>
      </Menu>
    </Box>
  );
};

export default LanguageDropdown;
