/* eslint-disable @typescript-eslint/quotes */
import { BoxProps } from "@chakra-ui/react";

export interface ILayoutColumnsConfig {
  layout: { [key: string]: string };
  gap: { [key: string]: string };
  cwidth: { [key: string]: BoxProps };
  vmargin: { [key: string]: BoxProps };
}
/* eslint-disable @typescript-eslint/naming-convention */
// Config is same as in ckeditor
// /assets/admin/ckeditor/plugins/ckeditor-layout-columns/css/layout-columns.css
export const LAYOUT_COLUMNS_CONFIG: ILayoutColumnsConfig = {
  layout: {
    "33-67": "1fr 2fr",
    "67-33": "2fr 1fr",
    "25-75": "1fr 3fr",
    "75-25": "3fr 1fr",
    "25-50-25": "1fr 2fr 1fr",
    "25-25-50": "1fr 1fr 2fr",
    "50-25-25": "2fr 1fr 1fr",
  },
  gap: {
    0: "0",
    1: ".25rem",
    2: ".5rem",
    4: "1.5rem",
    5: "2rem",
    6: "4rem",
  },
  cwidth: {
    shrink: {
      mx: "auto",
      w: "75%",
    },
    full: {
      mx: "-8px",
    },
    overflow: {
      mx: "-8px",
    },
  },
  vmargin: {
    1: {
      my: ".25rem",
    },
    2: {
      my: ".5rem",
    },
    3: {
      my: "1.5rem",
    },
    4: {
      my: "1.5rem",
    },
    5: {
      my: "2rem",
    },
    6: {
      my: "4rem",
    },
  },
};
