import React from "react";

import { ToastId, useToast, ToastProps, UseToastOptions } from "@chakra-ui/react";

import { Toast } from "../Toast";

type TToastParams = ToastProps & {
  title?: string;
  description?: string;
};

type TToastFn = (toastInfo: TToastParams) => ToastId;

interface IUseAppToastFnReturn {
  success: TToastFn;
  error: TToastFn;
  info: TToastFn;
  warning: TToastFn;
}

export const useAppToast = (options?: UseToastOptions): IUseAppToastFnReturn => {
  const toast = useToast({ position: "top-right", ...options });

  const success = ({ title, description, ...rest }: TToastParams) =>
    toast({
      render: ({ onClose }) => <Toast title={title} description={description} onClose={onClose} />,
      ...rest,
    });

  const error = ({ title, description, ...rest }: TToastParams) =>
    toast({
      render: ({ onClose }) => <Toast type="error" title={title} description={description} onClose={onClose} />,
      ...rest,
    });

  const info = ({ title, description, ...rest }: TToastParams) =>
    toast({
      render: ({ onClose }) => <Toast type="info" title={title} description={description} onClose={onClose} />,
      ...rest,
    });

  const warning = ({ title, description, ...rest }: TToastParams) =>
    toast({
      render: ({ onClose }) => <Toast type="warning" title={title} description={description} onClose={onClose} />,
      ...rest,
    });

  return {
    success,
    error,
    info,
    warning,
  };
};
