import pino, { LoggerOptions } from "pino";

const isDatadogEnabled = process.env.DD_ENABLED === "true";
const datadogApiKey = process.env.DD_API_KEY;
const datadogService = process.env.DD_SERVICE;
const datadogSource = process.env.DD_SOURCE;
const datadogTags = process.env.DD_TAGS;
const datadogSite = process.env.DD_SITE;

const config: LoggerOptions = {
  name: "Server logs",
  level: process.env.NEXT_LOG_LEVEL || "debug",
  enabled: isDatadogEnabled,
  transport: {
    target: "pino-datadog-transport",
    options: {
      ddClientConf: {
        authMethods: {
          apiKeyAuth: datadogApiKey,
        },
      },
      ddServerConf: {
        site: datadogSite,
      },
      service: datadogService,
      ddsource: datadogSource,
      tags: datadogTags,
    },
  },
};

export const logger = pino(config);

export const timeTook = (message: string, startTime: number): void => {
  logger.debug(`${message}, ${new Date().getTime() - startTime}ms`);
};
