import { FC, useEffect, useState } from "react";

import { Container, StackDivider, Box, Text, Link as ChakraLink, Stack, VStack, SimpleGrid } from "@chakra-ui/react";
import { getCookie } from "cookies-next";
import useTranslation from "next-translate/useTranslation";
import Link from "next/link";

import { Heading } from "@components/Heading/Heading";
import { parseHtml } from "@components/HTMLParser/utils";
import { IBlock, PageType } from "@features/cms/types";
import { TConfig } from "@features/config/types";
import { convertUrlToPath } from "@utils/utils";

import SubscribeInput from "../inputs/SubscribeInput";
import ProviderLinkList from "../ProviderLinkList";
import SocialButtonsList from "../SocialButtonsList";

const isWebView = getCookie("Is-Web-View");
const SCRIPT_ID = "OMNISEND_SCRIPT";

type TFooterProps = {
  footerNavLinks: TNavigationLink[];
  socialLinks: TBadge[];
  appProviders: TBadge[];
  paymentProviders: TBadge[];
  affiliatesBlock?: IBlock;
  pageType?: PageType;
  config: TConfig;
};

const Footer: FC<TFooterProps> = ({
  footerNavLinks,
  socialLinks,
  appProviders,
  paymentProviders,
  affiliatesBlock,
  pageType,
  config,
}) => {
  const { t } = useTranslation();
  const [shouldShow, setShouldShow] = useState<boolean>(false);

  useEffect(() => {
    setShouldShow(pageType !== PageType.REFERRALS);
  }, [pageType]);

  useEffect(() => {
    const s = document.createElement("script");
    const currentScript = document.getElementById(SCRIPT_ID);

    if (config?.omnisend_brand_id && !currentScript && !isWebView) {
      s.id = SCRIPT_ID;
      s.type = "text/javascript";
      s.appendChild(
        document.createTextNode(
          // eslint-disable-next-line max-len
          `window.omnisend = window.omnisend || [];omnisend.push(["accountID", "${config?.omnisend_brand_id}"]);!function(){var e=document.createElement("script");e.type="text/javascript",e.async=!0,e.src="https://omnisnippet1.com/inshop/launcher-v2.js";var t=document.getElementsByTagName("script")[0];t.parentNode.insertBefore(e,t)}();`
        )
      );
      delete window.OMNISEND_LAUNCHER_LOADED;
      document.body.appendChild(s);
    }

    return () => {
      const e = document.getElementById(SCRIPT_ID);

      if (e) {
        e.parentNode?.removeChild(e);
      }
    };
  }, [config?.omnisend_brand_id]);

  const affiliateBlock = (
    // NOTE: Affiliates block in mobile text must be centered and links/buttons full width.
    // eslint-disable-next-line @typescript-eslint/naming-convention
    <Box textAlign={{ base: "center", md: "unset" }} sx={{ "a, button": { width: { base: "full", md: "unset" } } }}>
      {affiliatesBlock && parseHtml(affiliatesBlock?.html)}
    </Box>
  );

  return (
    <Box bg="black" as="footer" data-test-id="footerBar">
      <Container role="contentinfo" bg="black" maxW="container.xl" color="white" py="32px">
        <VStack spacing="30px" divider={<StackDivider borderColor="whiteAlpha.200" />}>
          {shouldShow && (
            <>
              <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={10} w="full">
                <Box>
                  <Heading
                    textAlign={{
                      base: "center",
                      lg: "unset",
                    }}
                    fontWeight="regular"
                    as="h2"
                  >
                    <strong>{t("common:dont-miss")}</strong> {t("common:our-latest-news")}
                  </Heading>
                </Box>
                <Box display="flex" justifyContent={{ base: "center", lg: "end" }} alignItems="center" zIndex={0}>
                  <SubscribeInput />
                </Box>
              </SimpleGrid>
              <SimpleGrid columns={{ base: 1, lg: 3 }} spacing={10} w="full">
                {affiliateBlock}
                <Box display="flex" justifyContent="center">
                  <Box textAlign={{ base: "center", lg: "left" }}>
                    <Heading as="h4">{t("common:follow-us")}</Heading>
                    <SocialButtonsList socialLinks={socialLinks} />
                  </Box>
                </Box>
                <VStack spacing="8" w="full">
                  <ProviderLinkList label={t("common:download-app")} providersList={appProviders} />
                  <ProviderLinkList
                    spacing="3"
                    label={t("common:payment-methods")}
                    providersList={paymentProviders}
                    width="36px"
                  />
                </VStack>
              </SimpleGrid>
            </>
          )}
          <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={10} w="full">
            <Stack
              direction={{ base: "column", lg: "row" }}
              spacing="4"
              divider={<StackDivider borderColor="whiteAlpha.400" />}
            >
              {footerNavLinks.map((linkItem) =>
                linkItem.newTab ? (
                  <ChakraLink
                    key={linkItem.code}
                    href={convertUrlToPath(linkItem.url)}
                    py="0"
                    fontSize="textSize.labels"
                    colorScheme="white"
                    _hover={{ textDecoration: "none" }}
                    target={linkItem.newTab ? "_blank" : "_self"}
                  >
                    {linkItem.title}
                  </ChakraLink>
                ) : (
                  <Link
                    href={convertUrlToPath(linkItem.url)}
                    key={linkItem.code}
                    passHref
                    target={linkItem.newTab ? "_blank" : "_self"}
                  >
                    <ChakraLink
                      py="0"
                      as="span"
                      fontSize="textSize.labels"
                      colorScheme="white"
                      _hover={{ textDecoration: "none" }}
                    >
                      {linkItem.title}
                    </ChakraLink>
                  </Link>
                )
              )}
            </Stack>
            <Text color="whiteAlpha.700" fontSize="textSize.labels" textAlign={{ base: "center", lg: "right" }}>
              {t("common:copyright-voltas-footer", { date: `&copy; ${new Date().getFullYear()}` })}
            </Text>
          </SimpleGrid>
        </VStack>
      </Container>
    </Box>
  );
};

export default Footer;
