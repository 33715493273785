import { TextDecorationProps, TypographyProps } from "@chakra-ui/react";

import { fonts } from "./fonts";

export type THeadingType = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

type TTextStyles = Record<THeadingType, TextDecorationProps & TypographyProps>;

// `line-height` calculated using `https://www.thegoodlineheight.com/`
export const textStyles: TTextStyles = {
  h1: {
    fontFamily: fonts.heading,
    fontSize: { base: "34px", md: "64px" },
    fontWeight: "normal",
    textTransform: "uppercase",
    lineHeight: { base: "34px", md: "64px" },
  },
  h2: {
    fontFamily: fonts.heading,
    fontSize: { base: "28px", md: "56px" },
    fontWeight: "normal",
    textTransform: "uppercase",
    lineHeight: { base: "28px", md: "56px" },
  },
  h3: {
    fontSize: { base: "24px", md: "34px" },
    fontFamily: fonts.body,
    fontWeight: "bold",
    lineHeight: { base: "32px", md: "45px" },
  },
  h4: {
    fontSize: { base: "20px", md: "24px" },
    fontFamily: "body",
    fontWeight: "bold",
    lineHeight: "32px",
  },
  h5: {
    fontSize: "18px",
    fontFamily: "body",
    fontWeight: "bold",
    lineHeight: "24px",
  },
  h6: {
    fontSize: "16px",
    fontFamily: "body",
    fontWeight: "bold",
    lineHeight: "24px",
  },
};
