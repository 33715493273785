import pino, { LoggerOptions } from "pino";

const config: LoggerOptions = {
  name: "Client logs",
  level: process.env.NEXT_LOG_LEVEL || "info",
};

if (process.env.NODE_ENV !== "production") {
  config.transport = {
    target: "pino-pretty",
    options: {
      colorize: true,
    },
  };
}

export const logger = pino(config);
