import Cookies from "js-cookie";

import { COOKIE_NAME, SESSION_STORAGE_KEY } from "@utils/constants";
import { CheckoutStep } from "@utils/pages/checkout/types";
import { INewCustomerDetails } from "features/types";

/**
 * Get guest customer data from `sessionStorage`
 * @deprecated
 * @returns Guest customer data
 */
export const getGuestCustomerData = (): INewCustomerDetails | undefined => {
  const data = sessionStorage.getItem(SESSION_STORAGE_KEY.GUEST_CUSTOMER_DATA);

  if (!data) {
    return undefined;
  }

  return JSON.parse(data) as INewCustomerDetails;
};

/**
 * Set guest customer data to `sessionStorage`
 * @deprecated
 * @param data Guest customer data in checkout `customer` step.
 */
export const setGuestCustomerData = (data: INewCustomerDetails): void =>
  sessionStorage.setItem(SESSION_STORAGE_KEY.GUEST_CUSTOMER_DATA, JSON.stringify(data));

/**
 * @deprecated
 * Removes guest customer data from `sessionStorage`
 */
export const removeGuestCustomerData = (): void => sessionStorage.removeItem(SESSION_STORAGE_KEY.GUEST_CUSTOMER_DATA);

/**
 * Set checkout step to `Cookies`.
 */
export const setCheckoutStep = (checkoutStep: CheckoutStep): void => {
  Cookies.set(COOKIE_NAME.CHECKOUT_STEP, checkoutStep);
};

/**
 * Remove checkout step from `Cookies`.
 */
export const removeCheckoutStep = (): void => {
  Cookies.remove(COOKIE_NAME.CHECKOUT_STEP);
};

/**
 * Get checkout step from `Cookies`.
 */
export const getCheckoutStep = (): CheckoutStep | undefined =>
  Cookies.get(COOKIE_NAME.CHECKOUT_STEP) as CheckoutStep | undefined;

/**
 * Removes cart token cookie, guest customer and checkout step data from `Cookies`.
 */
export const removeCurrentCartData = (): void => {
  // Remove guest customer data.
  removeGuestCustomerData();

  // Remove current obd cart token from cookie.
  Cookies.remove(COOKIE_NAME.OBD_CART);

  // Remove checkout step.
  removeCheckoutStep();
};
