import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { TUserAdress } from "@modules/AddressForm/types";

import type { TAppState } from "../../store/store";
import userService from "./userActions";

export type TUserState = {
  addresses: TUserAdress[] | null;
  status: "loading" | "failed" | "success";
};

const initialState: TUserState = {
  addresses: null,
  status: "loading",
};

export const getAddresses = createAsyncThunk("user/getAddresses", async (_, thunkAPI) => {
  try {
    const { data } = await userService.getAddresses();
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const deleteAddress = createAsyncThunk("user/deleteAddress", async (id: number, thunkAPI) => {
  try {
    const { data } = await userService.deleteAddress(id);
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const makeDefaultAddress = createAsyncThunk("user/makeDefaultAddress", async (id: number, thunkAPI) => {
  try {
    const { data } = await userService.makeDefaultAddress(id);
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAddresses.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAddresses.fulfilled, (state, action) => {
        state.status = "success";
        state.addresses = action.payload;
      })
      .addCase(getAddresses.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(deleteAddress.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteAddress.fulfilled, (state, action) => {
        state.status = "success";
        state.addresses = action.payload;
      })
      .addCase(deleteAddress.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(makeDefaultAddress.pending, (state) => {
        state.status = "loading";
      })
      .addCase(makeDefaultAddress.fulfilled, (state, action) => {
        state.status = "success";
        state.addresses = action.payload;
      })
      .addCase(makeDefaultAddress.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const { reset } = userSlice.actions;
export const selectUser = (state: TAppState): TUserState => state.user;
export default userSlice.reducer;
