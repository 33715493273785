import config from "../i18n";

export const BASE_PUBLIC_BACKEND_URL = `${process.env.NEXT_PUBLIC_BACK_END_URL}/shop-api`;

/* eslint-disable @typescript-eslint/naming-convention */
export const QUERY_PARAM = {
  CAR_BRAND: "carBrand",
  FORGET_VEHICLE: "fv",
  COMPARE_TAB: "compareTab",
  FORCE_LANGUAGE: "fl",
  UTM_CAMPAIGN: "utm_campaign",
  IMPACT_CLICK_ID: "irclickid",
};
export const COOKIE_NAME = {
  USER_DATA: "userData",
  OBD_CART: "obdCartToken",
  VEHICLE: "vehicle",
  PREV_SELECTED_VEHICLE: "prevSelectedVehicle",
  IMPACT_CLICK_ID: "impactClickId",
  IMPACT_COOKIE_DATE: "impactCookieDate",
  /**
   * Defines if guest cart was merged to logged in user.
   */
  CART_MERGED_COOKIE: "cartMerged",
  /**
   * Save users current checkout step to handle browser refresh/language change
   */
  CHECKOUT_STEP: "checkout-step",
  USER_LOCALIZATION: "user-localization",
};
export const DEFAULT_LANGUAGE = config.defaultLocale;
export const CHANNEL_CODE = "EN";
export const LOCALE_CODE = "en_US";
export const CURRENCY = "USD";
export const LOCAL_STORAGE_KEYS = {
  TEMP_CART_TOKEN_PAYPAL: "temp-cart-token-paypal",
  TEMP_CART_TOKEN_STRIPE: "temp-cart-token-stripe",
};
export const SESSION_STORAGE_KEY = {
  GUEST_CUSTOMER_DATA: "guest-customer-data",
  VEHICLE_BASE_LIST_SEARCH: "vehicles-base-list-search",
  SELECTED_CAR_BRAND: "selected-car-brand",
};

export const CHAR_NUMBER_ONLY_REGEX = /^[A-Za-z0-9]+$/;
export const VIN_LENGTH = 17;
/**
 * @deprecated - create new one with correct place
 */
export const TRANSLATION_DOMAIN = {
  MESSAGES: "messages",
  USER: "user",
  PRODUCT: "product",
  VEHICLES: "vehicles",
  FEATURES: "features",
  PLANS: "plans",
  COMPARE: "compare",
  VALIDATION_ERROR: "validation",
  CART: "cart",
  CHECKOUT: "checkout",
  ERROR: "error",
  COMMON: "common",
  BLOG: "blog",
  VIN: "vin",
};
export const GUEST_USER_CART_TOKEN_EXPIRES_IN = 30; // minutes
export const CONTENT_BLOCK_CODE = {
  SHIPPING: "shipping",
  AFFILIATES: "affiliates",
};

export const CSS_VARIABLE = {
  MAIN_NAV_HEIGHT: "--main-nav-height",
  MOBILE_NAV_HEIGHT: "--mobile-nav-height",
  ACCORDION_CONTENT_OFFSET: "--accordion-content-offset",
};

export const DISTRIBUTOR_SELECTOR_PAGE_SLUG = "distributor-selector";
