const borderRadius = {
  radii: {
    small: "4px",
    default: "8px",
    input: "10px",
    md: "16px",
  },
};

export default borderRadius;
