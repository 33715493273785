import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getUserOrderHistory, IGetUserOrderHistoryParams } from "./orderActions";
import { IOrder, IOrdersHistory } from "./types";

type TOrderState = {
  history: IOrdersHistory | null;
};

const initialState: TOrderState = {
  history: null,
};

export const getOrderHistory = createAsyncThunk(
  "order/getOrderHistory",
  async (params: IGetUserOrderHistoryParams, thunkAPI) => {
    try {
      const { data } = await getUserOrderHistory(params);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    reset: () => initialState,
    updateOrderHistoryFromSsr: (state, action: PayloadAction<IOrdersHistory | null>) => {
      state.history = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOrderHistory.fulfilled, (state, action) => {
      if (!state.history) {
        state.history = action.payload;
        return;
      }

      const payloadHistoryOrders = action.payload.items;
      const newHistoryOrders: IOrder[] = [...state.history.items];

      const updatedOrderHistoryItems = payloadHistoryOrders.reduce((acc, currVal) => {
        const orderInStateIndex = acc.findIndex((order) => order.tokenValue === currVal.tokenValue);

        // If in new page returns an order from previous page - update it, otherwise - apply to the list.
        if (orderInStateIndex !== -1) {
          acc[orderInStateIndex] = currVal;

          return acc;
        }

        acc.push(currVal);

        return acc;
      }, newHistoryOrders);

      state.history = { ...action.payload, items: [...updatedOrderHistoryItems] };
    });
  },
});

export const { updateOrderHistoryFromSsr } = orderSlice.actions;

export default orderSlice.reducer;
