import { forwardRef } from "react";

import { Text, HeadingProps as ChakraHeadingProps } from "@chakra-ui/react";

import { THeadingType } from "theme/typography/textStyles";
/**
 * @default as - h2
 */
type THeadingProps = Omit<ChakraHeadingProps, "as"> & { as: THeadingType };

const Heading = forwardRef<HTMLHeadingElement, THeadingProps>((props, ref) => {
  const { as, fontWeight = "bold", ...restProps } = props;
  let marginBottom = 4;

  switch (as) {
    case "h4":
    case "h5":
    case "h6":
      marginBottom = 2;
      break;
    default:
      break;
  }

  return <Text ref={ref} textStyle={as} as={as} marginBottom={marginBottom} fontWeight={fontWeight} {...restProps} />;
});

Heading.displayName = "Heading";

export { Heading };
