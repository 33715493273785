import axios, { AxiosInstance } from "axios";

import { useUnauthorized } from "../common";
import { BASE_URL } from "../constants";
import { headersMiddleware } from "../utils";

const createAxiosInstance = (): AxiosInstance => {
  const instance = axios.create({ baseURL: BASE_URL });

  instance.interceptors.request.use(headersMiddleware());

  instance.interceptors.response.use((response) => {
    return response;
  }, useUnauthorized);

  return instance;
};

const api = createAxiosInstance();

export default api;
