import { FC, ReactElement } from "react";

import { Box, Spinner } from "@chakra-ui/react";

import styles from "./LoaderWrapper.module.css";

interface ILoaderWrapperProps {
  isLoading: boolean;
  children: ReactElement;
}

const LoaderWrapper: FC<ILoaderWrapperProps> = ({ isLoading, children }) => (
  <Box w="full" className={styles["loader-container"]}>
    <Box opacity={isLoading ? 0.5 : 1} style={{ pointerEvents: isLoading ? "none" : "auto" }}>
      {children}
    </Box>
    {isLoading && <Spinner className={styles.loader} />}
  </Box>
);

export default LoaderWrapper;
