export type TFormatPriceOptions = { locale?: string; currency?: string; fractionDigits?: number };

export const formatPrice = (value: number, opts: TFormatPriceOptions = {}): string => {
  const { locale = "en-US", currency = "USD", fractionDigits } = opts;
  const formatter = new Intl.NumberFormat(locale, {
    currency,
    style: "currency",
    maximumFractionDigits: fractionDigits ?? 2,
  });
  return formatter.format(value / 100);
};
