import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { API_ROUTES } from "@utils/api-routes";
import api from "@utils/axios/public";
import { IProduct } from "features/types";

export interface IGetAllProductsParams {
  carBrandCode?: string;
  taxonCode?: string;
}

export type TStateStatus = "loading" | "failed" | "success" | "idle";

type TProductsState = {
  products: IProduct[] | null;
  status: TStateStatus;
};

const initialState: TProductsState = {
  products: null,
  status: "idle",
};

export const getAllProducts = createAsyncThunk(
  "products/getAllProducts",
  async (params: IGetAllProductsParams, thunkAPI) => {
    try {
      const { data } = await api.get<{ items: IProduct[] }>(API_ROUTES.getAllProducts, {
        params,
      });

      return data.items;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const setAllProductsFromSSR = createAction("products/setAllProductsFromSSR", (products: IProduct[]) => {
  return { payload: products };
});

export const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllProducts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllProducts.fulfilled, (state, action) => {
        state.status = "success";
        state.products = action.payload;
      })
      .addCase(getAllProducts.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(setAllProductsFromSSR, (state, action) => {
        state.products = action.payload;
      });
  },
});

export default productsSlice.reducer;
