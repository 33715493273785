import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import type { TAppState } from "../../store/store";
import configService from "./configActions";
import { TConfig } from "./types";

export type TConfigState = {
  config: TConfig | null;
  status: "idle" | "loading" | "success" | "failed";
};

const initialState: TConfigState = {
  config: null,
  status: "idle",
};
/**
 * @deprecated moved to globalData and loaded directly from server
 */
export const getConfig = createAsyncThunk("config/getConfig", async (_, thunkAPI) => {
  try {
    const { data } = await configService.getConfig();
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setConfig: (state, action) => {
      return action.payload;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getConfig.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getConfig.fulfilled, (state, action) => {
        state.status = "success";
        state.config = action.payload;
      })
      .addCase(getConfig.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const { reset, setConfig } = configSlice.actions;
export const selectConfig = (state: TAppState): TConfigState => state.config;
export default configSlice.reducer;
