import { FC } from "react";

import { HStack, Link } from "@chakra-ui/react";

import BadgeImage from "@components/BadgeImage";

type TSocialButtonsListProps = {
  socialLinks: TBadge[];
};

const SocialButtonsList: FC<TSocialButtonsListProps> = ({ socialLinks }) => {
  return (
    <HStack spacing="4">
      {socialLinks.map((social) => (
        <Link href={social.url} target="_blank" rel="nofollow" key={social.image.path}>
          <BadgeImage
            badge={social.image}
            imageProps={{
              borderRadius: "full",
              maxH: "40px",
              maxW: "40px",
            }}
          />
        </Link>
      ))}
    </HStack>
  );
};

export default SocialButtonsList;
