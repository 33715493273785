import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import type { TAppState } from "../../store/store";
import cmsService from "./cmsActions";
import { ResourceCode, IBlock } from "./types";

type TBlocksByCode = {
  [key in ResourceCode]: IBlock | null;
};

export interface ICMSState {
  blocksByCode: TBlocksByCode;
}

const initialState: ICMSState = {
  blocksByCode: Object.fromEntries(new Map(Object.values(ResourceCode).map((value) => [value, null]))),
} as ICMSState;

export const getBlockByCode = createAsyncThunk("blocks/getBlockByCode", async (code: ResourceCode, thunkAPI) => {
  try {
    const { data } = await cmsService.getBlockByCode(code);
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const CmsSlice = createSlice({
  name: "cms",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBlockByCode.fulfilled, (state, action) => {
      state.blocksByCode[action.meta.arg] = action.payload;
    });
  },
});

export const selectBlocksByCode = (state: TAppState): TBlocksByCode => state.cms.blocksByCode;
export default CmsSlice.reducer;
