import type { ComponentStyleConfig } from "@chakra-ui/theme";

const Alert: ComponentStyleConfig = {
  parts: [],
  baseStyle: {
    container: {
      borderRadius: "10px",
      border: "1px solid",
      padding: "4",
    },
  },
  variants: {
    subtle: (props) => {
      const { colorScheme: c } = props;
      switch (c) {
        case "blue":
          return {
            container: {
              borderColor: "secondary.300",
              background: "rgba(75, 128, 221, 0.04)",
              color: "secondary.300",
            },
            icon: {
              color: "secondary.300",
            },
          };
        case "orange":
          return {
            container: {
              borderColor: "warning.500",
              background: "rgba(225, 166, 57, 0.08)",
            },
            icon: {
              color: "warning.500",
            },
          };
        default:
          return {};
      }
    },
    toastSuccess: {
      container: {
        bg: "success.800",
        color: "white",
      },
    },
    toastError: {
      container: {
        bg: "errors.500",
        color: "white",
      },
    },
    toastInfo: {
      container: {
        bg: "primary.300",
        color: "white",
      },
    },
    toastWarning: {
      container: {
        bg: "warning.700",
        color: "white",
      },
    },
    referral: {
      container: {
        bg: "gray.800",
        color: "white",
        borderColor: "gray.800",
        justifyContent: "center",
        padding: "9px 0",
        width: "100%",
        marginLeft: "0",
        fontSize: "16px",
        lineHeight: "18px",
        marginTop: "10px",
        borderRadius: "0",
      },
    },
  },
};

export default Alert;
