import { FC } from "react";

import { HStack, Text, Box } from "@chakra-ui/react";
import useTranslation from "next-translate/useTranslation";
import { FaChevronLeft } from "react-icons/fa";

type TNavigationItemBackProps = {
  onClick?: () => void;
};

const NavigationItemBack: FC<TNavigationItemBackProps> = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <HStack
      w="full"
      borderBottom="1px solid"
      borderColor="whiteAlpha.300"
      pb="4"
      mb="4"
      cursor="pointer"
      onClick={onClick}
    >
      <Box color="gray.400">
        <FaChevronLeft size={12} />
      </Box>
      <Text textTransform="uppercase">{t("common:back")}</Text>
    </HStack>
  );
};

export default NavigationItemBack;
