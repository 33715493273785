import { useEffect } from "react";

import { differenceInMilliseconds } from "date-fns";
import { useRouter } from "next/router";

import { APP_ROUTES } from "@utils/app-routes";

import { useSessionUtils } from "./hooks";

export const useSessionWatch = (): void => {
  const { session } = useSessionUtils();
  const router = useRouter();

  const signOut = () => router.push(APP_ROUTES.signOut);

  useEffect(() => {
    let timer;
    if (session?.expires) {
      const diff = differenceInMilliseconds(new Date(session.expires), new Date());

      if (diff > 0) {
        timer = setTimeout(signOut, diff);
      } else {
        signOut();
      }
    }

    return () => {
      clearTimeout(timer);
    };
  }, [session]);
};
