import { useState } from "react";

import useTranslation from "next-translate/useTranslation";

import { useAppToast } from "@components/Toast/hooks/UseAppToast";
import { getCart } from "@features/cart/cartSlice";
import { IShoppingCart } from "@features/cart/types";
import { TranslationDomain } from "@utils/types";
import { isEmpty } from "@utils/utils";

import { useAppDispatch } from "./hooks";

interface IUseCartCheckReturnFn {
  getIsCartValid: (hideToast?: boolean) => Promise<boolean>;
  getCartAndValidation: (hideToast?: boolean) => Promise<{ cart: IShoppingCart; isValid: boolean }>;
  isLoading: boolean;
}

const useCartCheck = (): IUseCartCheckReturnFn => {
  const { t } = useTranslation(TranslationDomain.VALIDATION_ERROR);
  const dispatch = useAppDispatch();
  const toast = useAppToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getIsCartValid = async (hideToast?: boolean) => {
    setIsLoading(true);
    const cart = await dispatch(getCart()).unwrap();
    const isValid = isEmpty(cart.cartValidationErrors);

    if (!isValid && !hideToast) {
      toast.error({ description: t("invalidCart") });
    }

    setIsLoading(false);

    return isValid;
  };

  const getCartAndValidation = async (hideToast?: boolean) => {
    setIsLoading(true);
    const cart = await dispatch(getCart()).unwrap();
    const isValid = isEmpty(cart.cartValidationErrors);

    if (!isValid && !hideToast) {
      toast.error({ description: t("invalidCart") });
    }

    setIsLoading(false);

    return { cart, isValid };
  };

  return {
    getIsCartValid,
    getCartAndValidation,
    isLoading,
  };
};

export default useCartCheck;
