import { FC, useState } from "react";

import { HStack, VStack, Text, Image, Box, useToken, Spinner } from "@chakra-ui/react";
import { motion } from "framer-motion";
import useTranslation from "next-translate/useTranslation";
import { MdDeleteOutline } from "react-icons/md";

import { removeCartItem, setSelectedShipping } from "@features/cart/cartSlice";
import { IProductItem } from "@features/types";
import { useAppDispatch } from "@utils/hooks/hooks";

import { PriceTag } from "./PriceTag";

type TCartPopupProductsProps = {
  cartItem: IProductItem;
};

const CartPopupProductsItem: FC<TCartPopupProductsProps> = ({ cartItem }) => {
  const { product, id, quantity } = cartItem;
  const [gray400] = useToken("colors", ["gray.400"]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const removeFromCart = async () => {
    setIsLoading(true);
    const result = await dispatch(removeCartItem(id)).unwrap();

    // Reset selected shipping when no items in the cart.
    if (result.items.length === 0) {
      dispatch(setSelectedShipping(null));
    }

    setIsLoading(false);
  };

  return (
    <Box
      data-test-id={`cartPopoverProduct_${product.name}`}
      as={motion.div}
      exit={{ opacity: 0, height: "0" }}
      w="full"
      position="relative"
      overflow="hidden"
      flexShrink={0}
    >
      <HStack w="full" py="4">
        {product.images && (
          <Image
            data-test-id={`cartPopoverProductImage_${product.name}`}
            flexShrink={0}
            width="112px"
            height="112px"
            fit="cover"
            src={product.images[0].path}
            alt={product.name}
            draggable="false"
            loading="lazy"
          />
        )}
        <VStack w="full" alignItems="start">
          <Text data-test-id={`cartPopoverProductName_${product.name}`} fontWeight="bold" noOfLines={2}>
            {product.name}
          </Text>
          <HStack w="full" justify="space-between">
            <Box>
              <PriceTag
                rootProps={{ align: "start" }}
                currentPrice={product.price.current}
                originalPrice={product.originalPrice?.current}
                currency={product.price.currency}
                salePriceProps={{ fontWeight: "bold" }}
              />
              <Text
                data-test-id={`cartPopoverProductQuantity_${product.name}`}
                color="gray.400"
                fontSize="textSize.small"
              >
                {t("cart:quantity-short", { qty: quantity })}
              </Text>
            </Box>
            {isLoading ? (
              <Spinner size="xs" />
            ) : (
              <MdDeleteOutline
                data-test-id={`cartPopoverProductRemoveIcon_${product.name}`}
                onClick={removeFromCart}
                cursor="pointer"
                color={gray400}
                height="14px"
              />
            )}
          </HStack>
        </VStack>
      </HStack>
    </Box>
  );
};

export default CartPopupProductsItem;
