import { LOCALES } from "@configs/translations";
import { defaultLocale } from "i18n";

export const getLocaleCodeByLanguage = (language: string = defaultLocale): string => {
  return LOCALES[language];
};

export const getCountryByLocaleCode = (locale: string): string => {
  return LOCALES[locale].split("_")[1];
};

export const getLanguageByLocaleCode = (locale: string = defaultLocale): string => {
  return locale.split("_")[0];
};
