import { LOCAL_STORAGE_KEYS } from "@utils/constants";
import cartService from "features/cart/cartActions";

import { ICartItemOptions } from "../../../../features/types";
import { getTempStripeCartToken } from "./utils";

interface IPaymentParams {
  productCode: string;
  method?: string;
  cartItemOptions: ICartItemOptions;
  cartToken?: string;
}

const getPaymentId = async ({ method, productCode, cartItemOptions, cartToken }: IPaymentParams): Promise<string> => {
  const cart = await cartService.createNewCart();
  if (!cart) {
    return "";
  }

  const tempCartToken = cartToken || cart.tokenValue;
  localStorage.setItem(LOCAL_STORAGE_KEYS.TEMP_CART_TOKEN_PAYPAL, tempCartToken);

  if (!cartToken) {
    const addToCartResult = await cartService
      .addItem(
        {
          productCode,
          ...cartItemOptions,
        },
        tempCartToken
      )
      .then((response) => {
        return response;
      });

    if (!addToCartResult?.tokenValue) {
      return "";
    }
  }

  const paymentOrderId = await cartService
    .initExpressCheckout(
      {
        method,
      },
      tempCartToken
    )
    .then((response) => {
      return response.paymentOrderId;
    });

  return paymentOrderId;
};

const createNewCart = async ({ productCode, cartItemOptions }: IPaymentParams): Promise<any> => {
  const cart = await cartService.createNewCart();
  if (!cart) {
    return false;
  }

  const tempCartToken = cart.tokenValue;
  localStorage.setItem(LOCAL_STORAGE_KEYS.TEMP_CART_TOKEN_STRIPE, tempCartToken);

  const addToCartResult = await cartService
    .addItem(
      {
        productCode,
        ...cartItemOptions,
      },
      tempCartToken
    )
    .then((response) => {
      return response;
    });

  if (addToCartResult?.tokenValue) {
    return addToCartResult?.tokenValue;
  }
  return false;
};

const getCartToken = async (
  orderDetails: { singleProduct: boolean; code: string },
  cartToken?: string,
  cartItemOptions?: ICartItemOptions
): Promise<string | null | undefined> => {
  let token: string | null | undefined = cartToken;

  if (orderDetails.singleProduct && cartItemOptions) {
    token = getTempStripeCartToken();

    if (!token) {
      await createNewCart({
        productCode: orderDetails.code,
        cartItemOptions,
      }).then((response) => {
        token = response;
      });
    }
  }

  return token;
};

const singleProductHandler = {
  getPaymentId,
  createNewCart,
  getCartToken,
};

export default singleProductHandler;
