import { useEffect, FC } from "react";
import { useTimeoutFn } from "react-use";

import { CheckCircleIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  useDisclosure,
  Text,
  HStack,
  Stack,
  VStack,
} from "@chakra-ui/react";
import useTranslation from "next-translate/useTranslation";
import Link from "next/link";
import { useRouter } from "next/router";

import PayPalExpress from "@components/checkout/utils/express/PayPalExpress";
import StripeExpress from "@components/checkout/utils/express/StripeExpress";
import DividerWithText from "@components/common/DividerWithText";

// eslint-disable-next-line import/no-absolute-path
import CartIcon from "/assets/icons/Cart.svg";

import { useAppStaticPath } from "@context/AppStaticPathContext";
import { selectCart, hideCartPopup } from "@features/cart/cartSlice";
import { CSS_VARIABLE } from "@utils/constants";
import { useAppSelector, useAppDispatch, useIsDesktop } from "@utils/hooks/hooks";
import useCartCheck from "@utils/hooks/useCartCheck";
import useExpressCheckoutOptions from "@utils/hooks/useExpressCheckoutOptions";
import { TranslationDomain } from "@utils/types";
import { isEmpty } from "@utils/utils";

import BraintreeExpress from "./checkout/utils/express/BraintreeExpress";
import CartPopupProducts from "./shoppingCart/CartPopupProducts";

const ShoppingCartIcon: FC = () => {
  const { t } = useTranslation(TranslationDomain.VALIDATION_ERROR);
  const cart = useAppSelector(selectCart);
  const dispatch = useAppDispatch();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const router = useRouter();
  const isDesktop = useIsDesktop();
  const isCartEmpty = (cart?.cart?.totals.quantityCount ?? 0) === 0;
  const isExpressCheckoutAvailable = cart?.cart?.tokenValue && cart?.cart?.totals?.total > 0;

  const { cartPath, checkoutPath, productsPath } = useAppStaticPath();

  const hiddenCartHrefs = [cartPath, checkoutPath];
  const canShowCart = !hiddenCartHrefs.includes(router.asPath);
  const hasExceededQuantityProduct = cart.cart?.items.some(
    (item) => item.product.itemsLeft && item.quantity > item.product.itemsLeft
  );
  const isCheckoutDisabled = hasExceededQuantityProduct || !isEmpty(cart.cart?.cartValidationErrors);
  const { getIsCartValid, isLoading } = useCartCheck();
  const expressCheckoutOptions = useExpressCheckoutOptions();

  const handleGoToCheckout = async () => {
    const isCartValid = await getIsCartValid();

    if (!isCartValid) {
      return;
    }

    router.push(checkoutPath);
  };

  const autoCloseCartPopup = () => {
    onClose();
    dispatch(hideCartPopup());
  };
  const [isReady, cancel, reset] = useTimeoutFn(autoCloseCartPopup, 3000);

  useEffect(() => {
    if (cart.showCartPopup && !isReady()) {
      onOpen();
      reset();
    } else {
      cancel();
    }
  }, [cancel, cart.showCartPopup, isReady, onOpen, reset]);

  const handleOnClose = () => {
    onClose();
    dispatch(hideCartPopup());
  };

  const renderCartButton = (isMobile?: boolean) => (
    <Box
      data-test-id="shoppingCartBtn"
      role="button"
      position="relative"
      cursor={isMobile || canShowCart ? "pointer" : "default"}
      px="2"
      borderColor="whiteAlpha.300"
    >
      <CartIcon width="26px" fill="white" />
      {cart.cart && cart.cart.totals.quantityCount > 0 && (
        <Box
          data-test-id="cartIconQuantityCountBubble"
          position="absolute"
          top="-10px"
          right="0"
          bg="primary.500"
          borderRadius="full"
          w="18px"
          h="18px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          fontSize="10px"
          color="white"
          border="2px solid black"
          fontFamily="body"
        >
          {cart.cart.totals.quantityCount}
        </Box>
      )}
    </Box>
  );

  if (!isDesktop) {
    return <Link href={cartPath}>{renderCartButton(true)}</Link>;
  }

  const cartPopoverHeader = () => {
    if (cart.showCartPopup) {
      return (
        <HStack fontSize="desktopHeadingSize.4" color="success.800" fontWeight="bold">
          <CheckCircleIcon />
          <Text>{t("cart:item-added")}</Text>
        </HStack>
      );
    }

    return (
      <HStack data-test-id="shoppingCartPopoverHeader" align="end">
        <Text data-test-id="cartPopoverHeaderText" fontSize="desktopHeadingSize.4" fontWeight="bold">
          {t("cart:shopping-cart")},
        </Text>
        <Text data-test-id="cartPopoverHeaderQuantityCount" color="gray.400">
          {t("cart:total-items", { count: cart.cart?.totals.quantityCount })}
        </Text>
      </HStack>
    );
  };

  return (
    <Popover isOpen={isOpen && canShowCart} onOpen={onOpen} onClose={handleOnClose} closeOnBlur>
      <PopoverTrigger>{renderCartButton()}</PopoverTrigger>
      <PopoverContent
        data-test-id="shoppingCartPopover"
        textColor="black"
        w="376px"
        maxH={`calc(100vh - var(${CSS_VARIABLE.MOBILE_NAV_HEIGHT}, var(${CSS_VARIABLE.MAIN_NAV_HEIGHT}, 80px)))`}
        overflowY="auto"
      >
        <PopoverArrow />
        <PopoverHeader border="none" p="6" position="relative">
          <PopoverCloseButton
            data-test-id="shoppingCartPopoverCloseBtn"
            _hover={{ bg: "none" }}
            _active={{ bg: "none" }}
            top="50%"
            right="0"
            transform="translate(-50%, -50%)"
          />
          {cartPopoverHeader()}
        </PopoverHeader>
        <PopoverBody p="0">
          <CartPopupProducts />
        </PopoverBody>
        {isCartEmpty ? (
          <PopoverFooter border="0" py="6">
            <Stack w="full">
              <Link href={productsPath}>
                <Button flex="1" colorScheme="primary">
                  {t("cart:continue-shopping")}
                </Button>
              </Link>
            </Stack>
          </PopoverFooter>
        ) : (
          <PopoverFooter border="0" pb="6" pt="0">
            <VStack w="full">
              {isExpressCheckoutAvailable && (
                <>
                  <DividerWithText text={t("cart:express-checkout")} />

                  <Stack spacing="3" w="100%">
                    {expressCheckoutOptions.ready && !!cart?.cart?.tokenValue && (
                      // eslint-disable-next-line react/jsx-no-useless-fragment
                      <>
                        {expressCheckoutOptions.braintree ? (
                          <BraintreeExpress cartToken={cart.cart.tokenValue} disabled={isCheckoutDisabled} />
                        ) : (
                          <>
                            <PayPalExpress cartToken={cart.cart?.tokenValue} disabled={isCheckoutDisabled} />
                            <StripeExpress cartToken={cart.cart?.tokenValue} disabled={isCheckoutDisabled} />
                          </>
                        )}
                      </>
                    )}
                  </Stack>

                  <DividerWithText text={t("cart:or")} />
                </>
              )}
              <HStack w="full">
                <Link href={cartPath}>
                  <Button data-test-id="cartViewCartBtn" flex="1" colorScheme="gray">
                    {t("cart:view-cart", { count: cart.cart?.totals.quantityCount })}
                  </Button>
                </Link>
                <Button
                  data-test-id="cartCheckoutBtn"
                  isDisabled={isCheckoutDisabled}
                  flex="1"
                  onClick={handleGoToCheckout}
                  isLoading={isLoading}
                >
                  {t("cart:checkout")}
                </Button>
              </HStack>
            </VStack>
          </PopoverFooter>
        )}
      </PopoverContent>
    </Popover>
  );
};

export default ShoppingCartIcon;
