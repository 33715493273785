import { FC } from "react";

import { useToken } from "@chakra-ui/react";
import Head from "next/head";

const Favicon: FC = () => {
  const [primary500] = useToken("colors", ["primary.500"]);

  // Generated using `https://realfavicongenerator.net/`
  return (
    <Head>
      <link rel="apple-touch-icon" sizes="180x180" href="/favicons/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicons/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicons/favicon-16x16.png" />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/favicons/safari-pinned-tab.svg" color={primary500} />
      <link rel="icon" href="/favicon.ico" />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="theme-color" content={primary500} />
    </Head>
  );
};

export default Favicon;
