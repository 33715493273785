import { tabsAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, cssVar, defineStyle } from "@chakra-ui/styled-system";

const $fg = cssVar("tabs-color");
const $bg = cssVar("tabs-bg");

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(parts.keys);

const baseStyleRoot = defineStyle((props) => {
  const { orientation } = props;
  return {
    display: orientation === "vertical" ? "flex" : "block",
  };
});

const baseStyleTab = defineStyle((props) => {
  const { isFitted } = props;

  return {
    flex: isFitted ? 1 : undefined,
    transitionProperty: "common",
    transitionDuration: "normal",
    _focusVisible: {
      zIndex: 1,
      boxShadow: "outline",
    },
    _disabled: {
      cursor: "not-allowed",
      opacity: 0.4,
    },
  };
});

const baseStyleTablist = defineStyle((props) => {
  const { align = "start", orientation } = props;

  const alignments: Record<string, string> = {
    end: "flex-end",
    center: "center",
    start: "flex-start",
  };

  return {
    justifyContent: alignments[align],
    flexDirection: orientation === "vertical" ? "column" : "row",
  };
});

const baseStyleTabpanel = defineStyle({
  p: 4,
});

const baseStyle = definePartsStyle((props) => ({
  root: baseStyleRoot(props),
  tab: baseStyleTab(props),
  tablist: baseStyleTablist(props),
  tabpanel: baseStyleTabpanel,
}));

const sizes = {
  md: definePartsStyle({
    tab: {
      fontSize: "sm",
      py: 3,
      px: 4,
    },
  }),
};

const variantLine = definePartsStyle((props) => {
  const { colorScheme: c, orientation } = props;
  const isVertical = orientation === "vertical";
  const borderProp = orientation === "vertical" ? "borderStart" : "borderBottom";
  const marginProp = isVertical ? "marginStart" : "marginBottom";

  return {
    tablist: {
      [borderProp]: "1px solid",
      borderColor: "inherit",
      w: "full",
    },
    tab: {
      [borderProp]: "3px solid",
      borderColor: "transparent",
      [marginProp]: "0px",
      _selected: {
        color: "inherit",
        [$fg.variable]: `colors.${c}.400`,
        borderColor: $fg.reference,
      },
      _active: {
        bg: "transparent",
      },
      _disabled: {
        _active: { bg: "none" },
      },
      color: "gray.400",
      bg: $bg.reference,
    },
  };
});

const variants = {
  line: variantLine,
};

export const tabsTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants,
  defaultProps: {
    size: "md",
    variant: "line",
    colorScheme: "primary",
  },
});

export default tabsTheme;
