/* eslint-disable max-lines */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { TBraintreeNonceData } from "@components/checkout/steps/Payment/types";
import { removeCurrentCartData, removeGuestCustomerData, setGuestCustomerData } from "@components/checkout/utils/utils";
import { CheckoutStep } from "@utils/pages/checkout/types";

import type { TAppState } from "../../store/store";
import {
  INewCustomerDetails,
  IPaymentMethod,
  IProduct,
  IShippingMethod,
  TDeliveryAndBillingAddress,
  TEnablePaymentMethods,
  IAddItemToCartBody,
  TPaymentData,
  TPaymentDetails,
  IOrderDetails,
  IAvailableShippingMethods,
  ICartItemOptions,
  IExpressPaymentMethod,
} from "../types";
import cartService from "./cartActions";
import { ISelectedShippingMethod, IShoppingCart, TApproveExpressCheckoutData } from "./types";

export interface ICheckoutFormState {
  isCartLoading: boolean;
  isDeliveryAndBillingAddressLoading: boolean;
  isAvailableShippingMethodsLoading: boolean;
  isShippingMethodLoading: boolean;
  isPaymentMethodsLoading: boolean;
  isSelectPaymentMethodLoading: boolean;
  isPaymentInitLoading: boolean;
  isPaymentCompletionLoading: boolean;
  isStripeConfirmationLoading: boolean;
  isCheckoutCompletionLoading: boolean;
}

export type TCartState = {
  cart: IShoppingCart | null;
  status: "idle" | "loading" | "failed" | "success" | "complete";
  availableShippingMethods: IAvailableShippingMethods | null;
  availablePaymentMethods: IPaymentMethod[];
  availableExpressPaymentMethods: IExpressPaymentMethod[];
  showCartPopup: boolean;
  similarProducts: IProduct[];
  paymentDetails: TPaymentDetails | null;
  paymentState: string;
  enabledMethods: TEnablePaymentMethods;
  newCustomerDetails: INewCustomerDetails | null;
  checkoutForm: ICheckoutFormState;
  checkoutCurrentStep: CheckoutStep;
  isShippingSameAsBilling: boolean;
  selectedShippingMethod: IShippingMethod | null;
};

const initialState: TCartState = {
  cart: null,
  status: "idle",
  showCartPopup: false,
  availableShippingMethods: null,
  availablePaymentMethods: [],
  availableExpressPaymentMethods: [],
  similarProducts: [],
  paymentDetails: null,
  paymentState: "not-selected",
  enabledMethods: {
    googlePay: false,
    applePay: false,
  },
  newCustomerDetails: null,
  checkoutForm: {
    isCartLoading: false,
    isDeliveryAndBillingAddressLoading: false,
    isAvailableShippingMethodsLoading: false,
    isShippingMethodLoading: false,
    isPaymentMethodsLoading: false,
    isSelectPaymentMethodLoading: false,
    isPaymentInitLoading: false,
    isPaymentCompletionLoading: false,
    isStripeConfirmationLoading: false,
    isCheckoutCompletionLoading: false,
  },
  checkoutCurrentStep: CheckoutStep.CUSTOMER,
  isShippingSameAsBilling: true,
  selectedShippingMethod: null,
};

export const getCart = createAsyncThunk("cart/getCart", async (_, thunkAPI) => {
  try {
    return await cartService.getCart();
  } catch (error) {
    let err;
    const newCart = await cartService.createNewCart(true).catch((e) => {
      err = e;
    });

    if (!newCart) {
      return thunkAPI.rejectWithValue(err);
    }

    return newCart;
  }
});

export const addCartItem = createAsyncThunk("cart/addCartItem", async (itemData: IAddItemToCartBody, thunkAPI) => {
  try {
    return await cartService.addItem(itemData);
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const updateCartItem = createAsyncThunk(
  "cart/updateCartItem",
  async ({ itemId, body }: { itemId: number; body: ICartItemOptions }, thunkAPI) => {
    try {
      return await cartService.updateCartItem(itemId, body);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const removeCartItem = createAsyncThunk("cart/removeItem", async (itemId: number, thunkAPI) => {
  try {
    return await cartService.removeItem(itemId);
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getAvailableShippingMethods = createAsyncThunk("cart/getAvailableShipmentMethods", async (_, thunkAPI) => {
  try {
    return await cartService.getAvailableShippingMethods();
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const setShippingMethod = createAsyncThunk(
  "cart/setShipmentProvider",
  async (method: ISelectedShippingMethod, thunkAPI) => {
    try {
      return await cartService.setShippingMethod(method.methodCode, method.pickUpPointId);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAvailablePaymentMethods = createAsyncThunk("cart/getAvailablePaymentMethods", async (_, thunkAPI) => {
  try {
    return await cartService.getAvailablePaymentMethods();
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getAvailableExpressPaymentMethods = createAsyncThunk(
  "cart/getAvailableExpressPaymentMethods",
  async (_, thunkAPI) => {
    try {
      return await cartService.getAvailableExpressPaymentMethods();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const setPaymentMethod = createAsyncThunk(
  "cart/setPaymentProvider",
  async (paymentData: TPaymentData, thunkAPI) => {
    try {
      if (paymentData.method !== "google-pay" && paymentData.method !== "apple-pay") {
        await cartService.setPaymentMethod(paymentData);
      } else {
        await cartService.setPaymentMethod({ method: "stripe" });
      }
      return paymentData;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateDeliveryAndBillingAddress = createAsyncThunk(
  "cart/updateDeliveryAndBillingAddress",
  async (addresses: TDeliveryAndBillingAddress, thunkAPI) => {
    try {
      return await cartService.updateDeliveryAndBillingAddress(addresses);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getSimilarProducts = createAsyncThunk("cart/getSimilarProducts", async (_, thunkAPI) => {
  try {
    return await cartService.getSimilarProducts();
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const addCouponCode = createAsyncThunk("cart/addCoupon", async (coupon: string, thunkAPI) => {
  try {
    return await cartService.addCouponCode(coupon);
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const initPayment = createAsyncThunk("cart/initPayment", async (value: TBraintreeNonceData | null, thunkAPI) => {
  try {
    return await cartService.initPayment(value);
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const completeCheckout = createAsyncThunk("cart/completeCheckout", async (userData: IOrderDetails, thunkAPI) => {
  try {
    return await cartService.completeCheckout(userData);
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const completePayment = createAsyncThunk("cart/completePayment", async (userData: IOrderDetails, thunkAPI) => {
  try {
    return await cartService.completePayment(userData);
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const initExpressCheckout = createAsyncThunk(
  "cart/initExpressCheckout",
  async (paymentData: TPaymentData, thunkAPI) => {
    try {
      return await cartService.initExpressCheckout(paymentData);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const approveExpressCheckout = createAsyncThunk(
  "cart/approveExpressCheckout",
  async (data: TApproveExpressCheckoutData, thunkAPI) => {
    try {
      const { cartToken, ...details } = data;
      return await cartService.approveExpressCheckout(details, cartToken);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// TODO add data type

export const updateExpressCheckout = createAsyncThunk("cart/updateExpressCheckout", async (data: any, thunkAPI) => {
  try {
    const { updateData, cartToken } = data;
    return await cartService.updateExpressCheckout(updateData, cartToken);
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const removeCouponCode = createAsyncThunk("cart/removeCoupon", async (_, thunkAPI) => {
  try {
    return await cartService.removeCouponCode();
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const setNewCustomerDetails = createAsyncThunk(
  "cart/setNewCustomerDetails",
  async (data: INewCustomerDetails | null): Promise<INewCustomerDetails | null> => Promise.resolve(data)
);

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    resetCart: () => initialState,
    hideCartPopup(state) {
      state.showCartPopup = false;
    },
    setPaymentWallets(state, action) {
      const enabledWallets = action.payload;
      if (enabledWallets) {
        state.enabledMethods.googlePay = enabledWallets.googlePay || false;
        state.enabledMethods.applePay = enabledWallets.applePay || false;
      }
    },
    setIsStripeConfirmationLoading(state, action) {
      state.checkoutForm.isStripeConfirmationLoading = action.payload;
    },
    setCheckoutCurrentStep(state, action) {
      state.checkoutCurrentStep = action.payload;
    },
    setIsShippingSameAsBilling(state, action) {
      state.isShippingSameAsBilling = action.payload;
    },
    setSelectedShipping(state, action: PayloadAction<IShippingMethod | null>) {
      state.selectedShippingMethod = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCart.pending, (state) => {
        state.checkoutForm.isCartLoading = true;
      })
      .addCase(getCart.rejected, (state) => {
        state.checkoutForm.isCartLoading = false;
      })
      .addCase(getCart.fulfilled, (state, action) => {
        state.checkoutForm.isCartLoading = false;
        state.cart = action.payload;

        if (state.cart?.tokenValue !== action.payload.tokenValue) {
          state.selectedShippingMethod = null;
        }
      })
      .addCase(addCartItem.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addCartItem.fulfilled, (state, action) => {
        state.status = "success";
        state.showCartPopup = true;
        state.cart = action.payload;
      })
      .addCase(addCartItem.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(updateCartItem.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateCartItem.fulfilled, (state, action) => {
        state.status = "success";
        state.cart = action.payload;
      })
      .addCase(updateCartItem.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(removeCartItem.pending, (state) => {
        state.status = "loading";
      })
      .addCase(removeCartItem.fulfilled, (state, action) => {
        state.status = "success";
        state.cart = action.payload;
      })
      .addCase(removeCartItem.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getAvailableShippingMethods.pending, (state) => {
        state.status = "loading";
        state.checkoutForm.isAvailableShippingMethodsLoading = true;
      })
      .addCase(getAvailableShippingMethods.fulfilled, (state, action) => {
        state.status = "success";
        state.availableShippingMethods = action.payload;
        state.checkoutForm.isAvailableShippingMethodsLoading = false;
      })
      .addCase(getAvailableShippingMethods.rejected, (state) => {
        state.status = "failed";
        state.checkoutForm.isAvailableShippingMethodsLoading = false;
      })
      .addCase(setShippingMethod.pending, (state) => {
        state.checkoutForm.isShippingMethodLoading = true;
      })
      .addCase(setShippingMethod.fulfilled, (state) => {
        state.checkoutForm.isShippingMethodLoading = false;
      })
      .addCase(setShippingMethod.rejected, (state) => {
        state.checkoutForm.isShippingMethodLoading = false;
      })
      .addCase(getAvailablePaymentMethods.pending, (state) => {
        state.status = "loading";
        state.checkoutForm.isPaymentMethodsLoading = true;
      })
      .addCase(getAvailablePaymentMethods.fulfilled, (state, action) => {
        state.status = "success";
        state.availablePaymentMethods = action.payload;
        state.checkoutForm.isPaymentMethodsLoading = false;
      })
      .addCase(getAvailablePaymentMethods.rejected, (state) => {
        state.status = "failed";
        state.checkoutForm.isPaymentMethodsLoading = false;
      })
      .addCase(getAvailableExpressPaymentMethods.fulfilled, (state, action) => {
        state.availableExpressPaymentMethods = action.payload;
      })
      .addCase(setPaymentMethod.pending, (state) => {
        state.checkoutForm.isSelectPaymentMethodLoading = true;
      })
      .addCase(setPaymentMethod.fulfilled, (state, action) => {
        const newMethod = state.availablePaymentMethods.find((m) => m.code === action.payload.method);
        state.status = "success";
        if (state.cart && newMethod) {
          state.cart = {
            ...state.cart,
            payments: [
              {
                ...state.cart?.payments[0],
                method: newMethod,
              },
            ],
          };
        }
        state.checkoutForm.isSelectPaymentMethodLoading = false;
      })
      .addCase(setPaymentMethod.rejected, (state) => {
        state.checkoutForm.isSelectPaymentMethodLoading = false;
      })
      .addCase(updateDeliveryAndBillingAddress.pending, (state) => {
        state.checkoutForm.isDeliveryAndBillingAddressLoading = true;
      })
      .addCase(updateDeliveryAndBillingAddress.fulfilled, (state, action) => {
        if (state.cart) {
          state.cart.billingAddress = action.payload.billingAddress;
          state.cart.shippingAddress = action.payload.shippingAddress;
        }
        state.checkoutForm.isDeliveryAndBillingAddressLoading = false;
      })
      .addCase(updateDeliveryAndBillingAddress.rejected, (state) => {
        state.checkoutForm.isDeliveryAndBillingAddressLoading = false;
      })
      .addCase(getSimilarProducts.fulfilled, (state, action) => {
        state.similarProducts = action.payload;
      })
      .addCase(initPayment.pending, (state) => {
        state.checkoutForm.isPaymentInitLoading = true;
      })
      .addCase(initPayment.fulfilled, (state, action) => {
        state.paymentDetails = action.payload;
        state.checkoutForm.isPaymentInitLoading = false;
      })
      .addCase(initPayment.rejected, (state) => {
        state.checkoutForm.isPaymentInitLoading = false;
      })
      .addCase(initExpressCheckout.fulfilled, (state, action) => {
        state.paymentDetails = action.payload;
      })
      .addCase(addCouponCode.fulfilled, (state, action) => {
        state.cart = action.payload;
      })
      .addCase(removeCouponCode.fulfilled, (state, action) => {
        state.cart = action.payload;
      })
      .addCase(completePayment.pending, (state) => {
        state.checkoutForm.isPaymentCompletionLoading = true;
      })
      .addCase(completePayment.fulfilled, (state) => {
        state.checkoutForm.isPaymentCompletionLoading = false;
        removeCurrentCartData();
      })
      .addCase(completePayment.rejected, (state) => {
        state.checkoutForm.isPaymentCompletionLoading = false;
      })
      .addCase(setNewCustomerDetails.fulfilled, (state, action) => {
        state.newCustomerDetails = action.payload;

        if (action.payload) {
          setGuestCustomerData(action.payload);
        } else {
          removeGuestCustomerData();
        }
      })
      .addCase(completeCheckout.pending, (state) => {
        state.checkoutForm.isCheckoutCompletionLoading = true;
      })
      .addCase(completeCheckout.fulfilled, (state) => {
        state.checkoutForm.isCheckoutCompletionLoading = false;
        removeCurrentCartData();
      })
      .addCase(completeCheckout.rejected, (state) => {
        state.checkoutForm.isCheckoutCompletionLoading = false;
      });
  },
});

export const {
  hideCartPopup,
  setPaymentWallets,
  resetCart,
  setIsStripeConfirmationLoading,
  setCheckoutCurrentStep,
  setIsShippingSameAsBilling,
  setSelectedShipping,
} = cartSlice.actions;
export const selectCart = (state: TAppState): TCartState => state.cart;
export default cartSlice.reducer;
/* eslint-enable max-lines */
