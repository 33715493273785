import Cookie from "js-cookie";

import { TBraintreeNonceData } from "@components/checkout/steps/Payment/types";
import api from "@utils/api";
import { COOKIE_NAME } from "@utils/constants";
import { OrderSummaryField } from "@utils/forms/types";

import {
  IAddItemToCartBody,
  TPaymentData,
  IOrderDetails,
  TPaymentDetails,
  IPaymentMethod,
  IAvailableShippingMethods,
  IShippingMethod,
  ICartItemOptions,
  IExpressPaymentMethod,
} from "../types";
import { IShoppingCart, TApproveExpressCheckoutData } from "./types";

// TODO: add a "check" for cart token, if token not available, create token
const getCartToken = () => Cookie.get(COOKIE_NAME.OBD_CART);

const getCart = async (): Promise<IShoppingCart> => {
  return api.get(`/carts/${getCartToken()}`);
};

const addItem = (itemData: IAddItemToCartBody, cartToken?: string | null): Promise<IShoppingCart> =>
  api.post(`/carts/${cartToken || getCartToken()}/items`, itemData);

const updateCartItem = (itemId: number, body: ICartItemOptions): Promise<IShoppingCart> =>
  api.put(`/carts/${getCartToken()}/items/${itemId}`, body);

const removeItem = async (itemId: number): Promise<IShoppingCart> =>
  api.delete(`/carts/${getCartToken()}/items/${itemId}`);

const getAvailableShippingMethods = async (): Promise<IAvailableShippingMethods> => {
  const data = await api.get(`/checkout/${getCartToken()}/shipping`);

  const shippingMethods: IShippingMethod[] = Object.values(data?.shipments?.[0]?.methods || {});

  return { hasRestrictedProducts: data.restrictedProductExists, methods: shippingMethods };
};

const setShippingMethod = async (
  methodCode: string | undefined,
  pickUpPointId: string | undefined
): Promise<IShoppingCart> => api.put(`/checkout/${getCartToken()}/shipping/0`, { method: methodCode, pickUpPointId });

const getAvailablePaymentMethods = async (): Promise<IPaymentMethod[]> => {
  const data = await api.get(`/checkout/${getCartToken()}/payment`);

  const paymentMethods: IPaymentMethod[] = Object.values(data?.payments?.[0]?.methods || {});

  return paymentMethods;
};

const getAvailableExpressPaymentMethods = async (): Promise<IExpressPaymentMethod[]> => {
  const data = await api.get("/payment-methods");

  return data;
};

const setPaymentMethod = async (paymentData: TPaymentData): Promise<any> =>
  api.put(`/checkout/${getCartToken()}/payment/0`, paymentData);

const updateDeliveryAndBillingAddress = async (addresses): Promise<any> => {
  const { shippingAddress, billingAddress, email } = addresses;

  delete shippingAddress.id;
  delete shippingAddress.default;
  delete billingAddress.id;
  delete billingAddress.default;

  await api.put(`/checkout/${getCartToken()}/address`, { shippingAddress, billingAddress, email });

  return addresses;
};

const completeCheckout = async (userData: IOrderDetails): Promise<void> =>
  api.put(`/checkout/${getCartToken()}/complete`, userData);

const getSimilarProducts = async (): Promise<any> => {
  const data = await api.get(`/checkout/${getCartToken()}/similar-products`);
  return data.items;
};

const initPayment = async (body?: TBraintreeNonceData | null): Promise<TPaymentDetails> =>
  api.post(`/orders/${getCartToken()}/payments/init`, body);

const completePayment = async (userData: IOrderDetails): Promise<undefined> => {
  const data = await api.post(`/orders/${getCartToken()}/payments/complete`, userData);
  Cookie.remove(COOKIE_NAME.OBD_CART);

  return data;
};

const initExpressCheckout = async (paymentData: TPaymentData, cartToken?: string | null): Promise<TPaymentDetails> =>
  api.post(`/orders/${cartToken || getCartToken()}/payments/init/express`, paymentData);

const approveExpressCheckout = async (
  data: Omit<TApproveExpressCheckoutData, "cartToken">,
  cartToken?: string | null
): Promise<any> => {
  const res = await api.post(`/orders/${cartToken || getCartToken()}/payments/complete/express`, data);

  if (cartToken === getCartToken()) {
    // move somewhere nicer
    Cookie.remove(COOKIE_NAME.OBD_CART);
  }

  return res;
};

const updateExpressCheckout = async (data, cartToken?: string | null): Promise<any> =>
  api.post(`/orders/${cartToken || getCartToken()}/payments/update/express`, data);

const addCouponCode = async (coupon: string): Promise<null> =>
  api.put(`/carts/${getCartToken()}/coupon`, { [OrderSummaryField.COUPON]: coupon });

const removeCouponCode = async (): Promise<any> => api.delete(`/carts/${getCartToken()}/coupon`);

const createNewCart = async (setCookie?: boolean): Promise<IShoppingCart> =>
  api.post("/carts").then((cart) => {
    if (setCookie && cart?.tokenValue) {
      Cookie.set(COOKIE_NAME.OBD_CART, cart.tokenValue);
    }

    return cart;
  });

const getBraintreeClientToken = async (cartToken?: string | null): Promise<{ token: string }> =>
  api.post(`/orders/${cartToken || getCartToken()}/payments/braintree/client/token`);

const cartService = {
  createNewCart,
  getCart,
  addItem,
  updateCartItem,
  removeItem,
  getAvailableShippingMethods,
  setShippingMethod,
  getAvailablePaymentMethods,
  getAvailableExpressPaymentMethods,
  setPaymentMethod,
  updateDeliveryAndBillingAddress,
  completeCheckout,
  getSimilarProducts,
  initPayment,
  completePayment,
  initExpressCheckout,
  approveExpressCheckout,
  updateExpressCheckout,
  addCouponCode,
  removeCouponCode,
  getBraintreeClientToken,
};

export default cartService;
