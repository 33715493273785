/* eslint-disable @typescript-eslint/naming-convention */
const LOCALES = {
  de: "de_DE",
  en: "en_US",
  es: "es_ES",
  fr: "fr_FR",
  it: "it_IT",
  cs: "cs_CZ",
};

// Should be map to date-fns locales
// Only ovveride with country prefix
// More info https://github.com/date-fns/date-fns/discussions/2724
const DATE_LOCALES = {
  ...Object.keys(LOCALES).reduce((acc, key) => {
    acc[key] = key;
    return acc;
  }, {}),
  en: "en-US",
};

const DEFAULT_LOCALE = "en";

module.exports = {
  LOCALES,
  DEFAULT_LOCALE,
  DATE_LOCALES,
};
