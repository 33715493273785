import { FC, useEffect, useRef, useState } from "react";

import { Box, HStack, ButtonGroup, Link as ChakraLink, Flex, useDisclosure } from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";

import ObdLogo from "@components/ObdLogo";
import { CURRENCY } from "@configs/currencies";
import { DEFAULT_LOCALE } from "@configs/translations";
import { PageType } from "@features/cms/types";
import { useUserLocalization } from "@modules/Localization";
import { CSS_VARIABLE } from "@utils/constants";
import { convertUrlToPath } from "@utils/utils";

import LanguageDropdown from "../../LanguageDropdown";
import ProfileDropDown from "../../ProfileDropdown";
import ShoppingCartIcon from "../../ShoppingCartIcon";
import MobileNavigation from "./MobileNavigation";
import MobileNavigationToggle from "./MobileNavigationToggle";

const Dropdown = dynamic(() => import("../../Dropdown"), { ssr: false });

interface INavigationProps {
  headerNavLinks: TNavigationLink[];
  languages: TLanguage[];
  userNavLinks: TNavigationLink[];
  pageType?: PageType;
}

const Navigation: FC<INavigationProps> = ({ headerNavLinks, languages, userNavLinks, pageType }) => {
  const { asPath, locale } = useRouter();
  const { isOpen, onToggle, onClose } = useDisclosure();
  const { currency, setCurrency } = useUserLocalization();
  const containerRef = useRef<HTMLDivElement>(null);
  const [shouldShow, setShouldShow] = useState<boolean>(false);

  useEffect(() => {
    onClose();
  }, [asPath, onClose]);

  const handleCurrencyChange = (value) => {
    setCurrency(value);
  };

  useEffect(() => {
    setShouldShow(pageType !== PageType.REFERRALS);
  }, [pageType]);

  const setNavHeightVariable = () => {
    const root = document?.querySelector<HTMLElement>(":root");
    const containerHeight = containerRef?.current?.clientHeight || 0;

    root?.style.setProperty(CSS_VARIABLE.MAIN_NAV_HEIGHT, `${containerHeight}px`);
  };

  const removeNavHeightVariable = () => {
    const root = document?.querySelector<HTMLElement>(":root");
    root?.style.removeProperty(CSS_VARIABLE.MAIN_NAV_HEIGHT);
  };

  useEffect(() => {
    document?.addEventListener("load", setNavHeightVariable, false);
    document?.addEventListener("resize", setNavHeightVariable, false);

    return () => {
      document?.removeEventListener("load", setNavHeightVariable);
      document?.removeEventListener("resize", setNavHeightVariable);
      removeNavHeightVariable();
    };
  }, []);

  useEffect(() => {
    const containerHeight = containerRef?.current?.clientHeight || 0;
    const root = document.querySelector<HTMLElement>(":root");

    root?.style.setProperty(CSS_VARIABLE.MAIN_NAV_HEIGHT, `${containerHeight}px`);

    return () => {
      root?.style.removeProperty(CSS_VARIABLE.MAIN_NAV_HEIGHT);
    };
  }, [containerRef?.current?.clientHeight]);

  const desktopNav = (
    <Flex justify="space-between" flex={1} display={{ base: "none", lg: "flex" }}>
      <ButtonGroup spacing={5}>
        {headerNavLinks.map((navItem) => (
          // Should be replaced with next/link after it works with car brand selector functionality
          <ChakraLink
            data-test-id={`navigation_${navItem.title}`}
            key={navItem.code}
            href={`${locale !== DEFAULT_LOCALE ? `/${locale}` : ""}${convertUrlToPath(navItem.url)}`}
            target={navItem.newTab ? "_blank" : "_self"}
            color="white"
            textTransform="uppercase"
            _hover={{ textDecoration: "none" }}
            fontWeight="medium"
          >
            {navItem.title}
          </ChakraLink>
        ))}
      </ButtonGroup>
      <HStack spacing="3">
        <Dropdown
          currentSelection={currency}
          list={Object.values(CURRENCY)}
          buttonProps={{ textTransform: "uppercase" }}
          listItemProps={{ textTransform: "uppercase" }}
          onChange={handleCurrencyChange}
        />
        <LanguageDropdown languages={languages} />
        <ShoppingCartIcon key={asPath} />
        <ProfileDropDown userNavLinks={userNavLinks} />
      </HStack>
    </Flex>
  );
  const mobileNav = (
    <HStack spacing={{ base: 5, sm: 10 }} display={{ base: "flex", lg: "none" }}>
      <ShoppingCartIcon key={asPath} />
      <MobileNavigationToggle onToggle={onToggle} isOpen={isOpen} />
    </HStack>
  );

  return (
    <Box
      data-test-id="navigationBar"
      ref={containerRef}
      as="nav"
      position="fixed"
      width="100%"
      zIndex="100"
      color="white"
      bg="black"
      px={{ base: "16px", md: "32px" }}
      py={{ base: "14px", md: "24px" }}
    >
      <HStack spacing={{ base: 1, sm: 10 }} justify="space-between">
        <ObdLogo />
        {shouldShow ? (
          <>
            {mobileNav}
            {desktopNav}
          </>
        ) : undefined}
      </HStack>

      <AnimatePresence>
        {isOpen && (
          <Box
            as={motion.div}
            position="absolute"
            right="0"
            transition="0.15s linear"
            initial={{ x: 300 }}
            animate={{ x: 0 }}
            exit={{ x: 300 }}
          >
            <MobileNavigation navLinks={headerNavLinks} languages={languages} userNavLinks={userNavLinks} />
          </Box>
        )}
      </AnimatePresence>
    </Box>
  );
};
export default Navigation;
