import type { ComponentStyleConfig } from "@chakra-ui/theme";

const Radio: ComponentStyleConfig = {
  variants: {
    full: {
      label: {
        width: "100%",
      },
    },
  },
};

export default Radio;
