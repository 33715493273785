/* eslint-disable @typescript-eslint/naming-convention */
import { KeyboardEventHandler } from "react";

import { IPageData } from "@components/ResourceView/types";
import { ICarBrand, ICartItemOptions, IProduct, TTaxon } from "@features/types";
import { IVehicleMake } from "@features/vehicles/types";
import { API_ROUTES } from "@utils/api-routes";
import api from "@utils/axios/public";
import { IPage, ISection } from "features/cms/types";

import { APP_ROUTES, BLACKLISTED_URLS } from "./app-routes";
import { DEFAULT_LANGUAGE, QUERY_PARAM } from "./constants";

export const isNullOrUndefined = <T>(obj: T | null | undefined): obj is null | undefined => {
  return typeof obj === "undefined" || obj === null;
};

export const isEmpty = (val: any) => val == null || !(Object.keys(val) || val).length;

export type TCarBrandImagePathType = "dark" | "light";
export const getCarBrandImagePath = (carBrand: ICarBrand, type: TCarBrandImagePathType): string | undefined => {
  const totalCarBrandImageTypes = carBrand.images.length;

  if (totalCarBrandImageTypes === 1) {
    return carBrand.images[0].path;
  }

  return carBrand.images.find((image) => image.type === type)?.path;
};

export const convertUrlToPath = (url: string) => {
  try {
    const parsedUrl = new URL(url);
    return url.replace(parsedUrl.origin, "");
  } catch (e) {
    return url;
  }
};

type TGroupProductsByTaxonParams = {
  taxons: TTaxon[];
  products: IProduct[];
  selectedTaxon?: TTaxon;
};

type TGroupProductsByTaxonFnReturn = {
  taxon: TTaxon;
  products: IProduct[];
}[];

export const groupProductsByTaxon = ({
  products,
  taxons,
  selectedTaxon,
}: TGroupProductsByTaxonParams): TGroupProductsByTaxonFnReturn => {
  return taxons.reduce((prevVal, currTaxon) => {
    const taxonProducts = products.filter((product) => product.taxons.main === currTaxon.code);

    prevVal.push({
      taxon: currTaxon,
      products: selectedTaxon
        ? taxonProducts.filter((product) => product.taxons.main === selectedTaxon.code)
        : taxonProducts,
    });

    return prevVal;
  }, [] as TGroupProductsByTaxonFnReturn);
};

export const camelize = (s) => s.replace(/-./g, (x) => x[1].toUpperCase());

export const camelizeObjectKeys = (obj) =>
  Object.entries(obj || {}).reduce((op, [key, value]) => {
    op[camelize(key)] = value;
    return op;
  }, {});

export const getSignInCallbackUrl = (routerPath: string, callbackUrl?: string, locale?: string): string => {
  const url = callbackUrl || routerPath;
  const isDefaultLocale = locale === DEFAULT_LANGUAGE;

  // If guest signs in `order-status` page - redirect to home.
  return BLACKLISTED_URLS.some((blacklistedUrl) => url.includes(blacklistedUrl))
    ? `/${!isDefaultLocale ? locale : ""}`
    : url;
};

export const getSignOutCallbackUrl = (routerPath: string, callbackUrl?: string, locale?: string): string => {
  const url = callbackUrl || routerPath;
  const isDefaultLocale = locale === DEFAULT_LANGUAGE;

  // If user signs out in account page - redirect to home.
  return url.includes(APP_ROUTES.account.base) ? `/${!isDefaultLocale ? locale : ""}` : url;
};

// NOTE: Improve this when mobile sections will be used. .mobile section.mobile
export const isSectionValid = (sections: ISection[]): boolean => {
  return !!sections.find((section: ISection) => section.desktop.blocks.length > 0);
};

type TGetFallbackPageParams = Partial<IPage> &
  Pick<IPage, "resourceCategory" | "resourceType" | "name" | "title" | "slug">;

export const getMockPage = (data: TGetFallbackPageParams): IPage => {
  return {
    resourceCategory: data.resourceCategory,
    sections: data.sections ?? [],
    resourceType: data.resourceType,
    breadcrumb: data.breadcrumb ?? "",
    descriptionWhenLinked: data.descriptionWhenLinked ?? "",
    image: data.image ?? "",
    metaDescription: data.metaDescription ?? "",
    metaKeywords: data.metaKeywords ?? "",
    nameWhenLinked: data.nameWhenLinked ?? "",
    name: data.name,
    title: data.title,
    slug: data.slug,
  };
};

export const getIsCreditsProduct = (product: IProduct): boolean => product.digital && !product.plan;

export const getIsRedeemQuantityRequired = (
  product: IProduct,
  productQty: number,
  isRedeemable: boolean,
  isAutoRedeem: boolean
): boolean => isRedeemable && isAutoRedeem && getIsCreditsProduct(product) && productQty > 1;

export const getCartItemOptions = (
  product: IProduct,
  productQty: number,
  redeemQty: number,
  isRedeemable: boolean,
  isAutoRedeem: boolean
): ICartItemOptions => {
  const isCredits = getIsCreditsProduct(product);

  return {
    quantity: productQty,
    ...(product.digital
      ? {
          autoRedeem: isAutoRedeem,
        }
      : {}),
    ...(isRedeemable && isAutoRedeem && isCredits
      ? {
          redeemQuantity: redeemQty,
        }
      : {}),
  };
};

// regex to add forward slash if path is missing it,
// because path is mostly given from router.asPath which always has forward slash
export const activeUrl = (links: TNavigationLink[], path: string): TNavigationLink | undefined =>
  links.find((link) => link.url.replace(/^\/?/, "/") === path);

export const getVehicleSelectorRoutePath = (pageData: IPageData, vehicleMake: IVehicleMake, query?: string): string => {
  const additionalQuery = query ? `&${query}` : "";

  if (vehicleMake.voltasMappingName !== pageData.selectedVehicleBrand) {
    return `/${pageData.slug}?${vehicleMake.voltasMappingName}${additionalQuery}`;
  }

  return `/${pageData.slug}?${QUERY_PARAM.FORGET_VEHICLE}=true${additionalQuery}`;
};

export const range = (start: number, end: number): number[] =>
  Array.from({ length: end - start + 1 }, (_, i) => start + i);

export const generateId = (length: number): string => {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

export const isBrowser = (): boolean => typeof window !== "undefined";

export const isElementInViewport = (el: HTMLElement | null | undefined): boolean => {
  const rect = el?.getBoundingClientRect();

  return (
    !!rect &&
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const omitData = <T extends object = Record<string, unknown>, K extends keyof T = keyof T>(
  obj: T,
  keys: K[]
): Omit<T, K> => {
  const result = Object.entries(obj).reduce((prevValue, currValue) => {
    const shouldSkip = keys.find((key) => currValue[0] === key);

    if (shouldSkip) {
      return prevValue;
    }

    return { ...prevValue, [currValue[0]]: currValue[1] };
  }, {} as Omit<T, K>);

  return result;
};

export const allowOnlyNumberKey: KeyboardEventHandler<HTMLInputElement> = (e) => {
  const specialKeys = [8, 46, 37, 38, 39, 40]; // backspace, delete, arrow keys
  const keyCode = e.which ? e.which : e.keyCode;

  if (
    !(
      (keyCode >= 48 && keyCode <= 57 && Number.isFinite(parseInt(e.key, 10))) ||
      (keyCode >= 96 && keyCode <= 105) ||
      specialKeys.indexOf(keyCode) !== -1
    )
  ) {
    e.preventDefault();
  }
};

export const limitMaxCount = (maxCount: number | undefined, limit = 1000): number =>
  !!maxCount && maxCount <= limit ? maxCount : limit;

export const timeout = (ms: number) => {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const waitForOrderToBeReady = async (cartToken: string | null | undefined, wait = false) => {
  if (!cartToken) {
    return Promise.reject();
  }

  if (wait) {
    await timeout(1000);
  }

  const res = await api(API_ROUTES.getOrder(cartToken)).catch(() => null);

  if (res?.status === 200) {
    return Promise.resolve();
  }
  await waitForOrderToBeReady(cartToken, true).catch(() => undefined);

  return Promise.resolve();
};
