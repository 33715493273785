/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useCallback, useEffect, useMemo } from "react";

import { getAvailableExpressPaymentMethods, selectCart } from "@features/cart/cartSlice";
import { PaymentMethodCode } from "@features/types";
import { isEmpty } from "@utils/utils";

import { useAppDispatch, useAppSelector } from "./hooks";

export default function useExpressCheckoutOptions() {
  const dispatch = useAppDispatch();
  const cart = useAppSelector(selectCart);
  const { availableExpressPaymentMethods: paymentMethods } = cart;

  const loadPaymentMethods = useCallback(() => {
    dispatch(getAvailableExpressPaymentMethods());
  }, [dispatch]);

  useEffect(() => {
    if (isEmpty(paymentMethods)) {
      loadPaymentMethods();
    }
  }, [paymentMethods, loadPaymentMethods]);

  const expressCheckoutOptions = useMemo(
    () => ({
      ready: !!paymentMethods.length,
      braintree: !!paymentMethods?.find((m) => m.code === PaymentMethodCode.BRAINTREE),
    }),
    [paymentMethods]
  );

  return expressCheckoutOptions;
}
