import { FC } from "react";

import { Box } from "@chakra-ui/react";

import style from "./CountrySelect.module.css";

type TCountryFlagProps = {
  countryCode: string;
  round?: boolean;
  width?: string | number;
  height?: string | number;
};

const CountryFlag: FC<TCountryFlagProps> = ({ countryCode, round, width, height }) => {
  return (
    <Box
      as="div"
      width={width}
      height={height}
      borderRadius={round ? "full" : 0}
      className={`${style.flag} ${style[`flag-${(countryCode || "").toLowerCase()}`]}`}
    />
  );
};

CountryFlag.defaultProps = {
  round: false,
  width: "24px",
  height: "24px",
};

export default CountryFlag;
