import { LOCAL_STORAGE_KEYS } from "@utils/constants";

export const getTempStripeCartToken = (): string | null =>
  localStorage.getItem(LOCAL_STORAGE_KEYS.TEMP_CART_TOKEN_STRIPE);

export const removeTempStripeCartToken = (): void => localStorage.removeItem(LOCAL_STORAGE_KEYS.TEMP_CART_TOKEN_STRIPE);

export const getTempPaypalCartToken = (): string | null =>
  localStorage.getItem(LOCAL_STORAGE_KEYS.TEMP_CART_TOKEN_PAYPAL);

export const removeTempPaypalCartToken = (): void => localStorage.removeItem(LOCAL_STORAGE_KEYS.TEMP_CART_TOKEN_PAYPAL);
