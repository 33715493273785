import type { ComponentStyleConfig } from "@chakra-ui/theme";

const Button: ComponentStyleConfig = {
  baseStyle: {
    fontWeight: "medium",
    borderRadius: "8px",
    lineHeight: "19px",
    fontSize: "textSize.labels",
    padding: "10px 24px 11px",
    outline: "none",
    boxShadow: "none",
    minH: "48px",
    _active: {
      outline: "none",
      boxShadow: "none",
    },
    _focus: {
      outline: "none",
      boxShadow: "none",
    },
  },
  variants: {
    secondaryDark: {
      bg: "gray.600",
      color: "white",
      _hover: {
        bg: "#4F4F4F",
        _disabled: {
          bg: "gray.600",
        },
      },
      _active: {
        bg: "gray.700",
      },
      _disabled: {
        bg: "gray.600",
        opacity: 1,
        color: "#A9A9A9",
      },
    },
    link: {
      minH: "auto",
      bg: "transparent",
      color: "black",
      paddingLeft: 0,
      paddingRight: 0,
      _hover: {
        textDecoration: "underline",
        _disabled: {
          textDecoration: "none",
        },
      },
      _active: {
        textDecoration: "none",
      },
      _disabled: {
        opacity: 0.6,
      },
    },
    linkDark: {
      minH: "auto",
      bg: "transparent",
      color: "white",
      height: "unset",
      padding: 0,
      _hover: {
        textDecoration: "underline",
        _disabled: {
          textDecoration: "none",
        },
      },
      _active: {
        textDecoration: "none",
      },
      _disabled: {
        opacity: 0.6,
      },
    },
    solidDark: {
      bg: "gray.dark",
      color: "white",
      _hover: {
        bg: "gray.dark",
      },
      _loading: {
        bg: "gray.dark",
      },
      _active: {
        bg: "gray.dark",
      },
    },
    outline: {
      border: "1px solid",
      borderColor: "black",
      color: "black",
      borderRadius: "8px",
      bg: "transparent",
      _hover: {
        bg: "transparent",
        borderColor: "black",
        border: "2px solid",
        paddingLeft: "15px",
        paddingRight: "15px",
      },
      _active: {
        bg: "transparent",
        borderColor: "black",
        border: "2px solid",
        paddingLeft: "15px",
        paddingRight: "15px",
      },
      _disabled: {
        bg: "transparent",
        borderColor: "black",
      },
    },
    outlineRounded: {
      border: "2px solid",
      borderColor: "blackAlpha.400",
      color: "black",
      borderRadius: "full",
      bg: "transparent",
      _hover: {
        bg: "transparent",
        borderColor: "black",
      },
      _active: {
        bg: "transparent",
        borderColor: "primary.500",
      },
      _disabled: {
        bg: "transparent",
        borderColor: "black",
      },
    },
    actionLink: {
      lineHeight: "1",
      border: "none",
      paddingRight: "0",
      paddingLeft: "0",
      paddingTop: "0",
      paddingBottom: "0",
      fontSize: "textSize.labels",
      fontWeight: "normal",
      background: "none",
      color: "gray.300",
      textDecoration: "none",
      height: "auto",
      _hover: {
        color: "gray.400",
      },
      _active: {
        color: "gray.500",
      },
    },
    menuButton: {
      bg: "gray.50",
      _hover: {
        bg: "gray.50",
      },
      _active: {
        bg: "gray.50",
      },
      fontWeight: "normal",
      _focus: {
        outline: "2px solid",
        outlineColor: "blue.500",
      },
    },
    mobileMenuButton: {
      p: "4",
      w: "full",
      textAlign: "start",
      fontSize: "textSize.regular",
      fontWeight: "normal",
      borderBottom: "1px solid",
      borderColor: "gray.200",
      bg: "white",
      color: "black",
      borderRadius: "0",
      height: "auto",
      _hover: {
        bg: "none",
      },
      _active: {
        bg: "none",
      },
    },
    gray: {
      bg: "common.gray",
      color: "common.black",
      _hover: {
        bg: "gray.300",
        _disabled: {
          bg: "common.gray",
          color: "gray.300",
        },
      },
      _active: {
        bg: "common.gray",
      },
      _disabled: {
        bg: "common.gray",
        color: "gray.300",
      },
    },
  },
  defaultProps: {
    variant: "solid",
    colorScheme: "primary",
  },
};

export default Button;
