import type { ComponentStyleConfig } from "@chakra-ui/theme";

const Select: ComponentStyleConfig = {
  baseStyle: {
    field: {
      bg: "gray.50",
      _focus: {
        bg: "gray.50",
      },
      _hover: {
        bg: "gray.100",
      },
      _disabled: {
        bg: "gray.100",
      },
    },
  },
  variants: {
    filled: {
      field: {
        bg: "gray.50",
        borderRadius: "default",
        _focus: {
          bg: "gray.50",
        },
        _hover: {
          bg: "gray.100",
        },
      },
    },
  },
  defaultProps: {
    size: "lg",
    focusBorderColor: "blue.500",
    errorBorderColor: "pro.500",
  },
};

export default Select;
