import React, { useState, useEffect, FC } from "react";

import { Button, ButtonProps } from "@chakra-ui/react";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { PaymentRequest, PaymentRequestPaymentMethodEvent } from "@stripe/stripe-js";
import { CanMakePaymentResult } from "@stripe/stripe-js/types/stripe-js/payment-request";
import { useRouter } from "next/router";

import Image from "@components/Image";
import { useAppStaticPath } from "@context/AppStaticPathContext";
import getStripe from "@utils/get-stripejs";
import { useAppDispatch, useAppSelector, useServerErrorUtils } from "@utils/hooks/hooks";
import { waitForOrderToBeReady } from "@utils/utils";
import cartService from "features/cart/cartActions";
import { approveExpressCheckout, updateExpressCheckout, selectCart } from "features/cart/cartSlice";
import { selectConfig } from "features/config/configSlice";
import { ICartItemOptions, IProduct, PaymentMethodCode } from "features/types";

import singleProductHandler from "./singleProductHandlerUtils";
import { getTempStripeCartToken, removeTempStripeCartToken } from "./utils";

interface IPaymentButton extends ButtonProps {
  paymentMethods?: CanMakePaymentResult;
}

const PaymentButton: FC<IPaymentButton> = (props) => {
  const { config } = useAppSelector(selectConfig);
  const { paymentMethods, ...restProps } = props;

  return paymentMethods?.applePay || paymentMethods?.googlePay ? (
    <Button
      {...restProps}
      w="full"
      backgroundColor="#000000"
      borderRadius={4}
      _hover={{
        backgroundColor: "#3c4043",
      }}
      _active={{
        backgroundColor: "#5f6368",
      }}
    >
      {paymentMethods?.applePay ? (
        <Image src={config?.apple_pay_icon_light || ""} width="60px" height="40px" objectFit="contain" />
      ) : (
        <Image src={config?.google_pay_icon_light || ""} width="60px" height="40px" objectFit="contain" />
      )}
    </Button>
  ) : null;
};

type TCheckoutFormProps = {
  cartToken?: string;
  product?: IProduct;
  cartItemOptions?: ICartItemOptions;
  disabled?: boolean;
};

const CheckoutForm: FC<TCheckoutFormProps> = ({ cartToken, product, cartItemOptions, disabled }) => {
  const stripe = useStripe();
  const { toastServerError } = useServerErrorUtils();
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>();
  const [paymentMethods, setPaymentMethods] = useState<CanMakePaymentResult>();
  const router = useRouter();
  const dispatch = useAppDispatch();
  const { getOrderStatusPath } = useAppStaticPath();
  const cart = useAppSelector(selectCart);
  let orderDetails: any;
  if (product && cartItemOptions) {
    orderDetails = {
      currency: product.price.currency,
      code: product.code,
      amount: product.price.current * cartItemOptions.quantity,
      singleProduct: true,
      requestShipping: !product.digital,
    };
  } else if (cart.cart) {
    orderDetails = {
      code: null,
      currency: cart.cart.currency,
      amount: cart.cart.totals.total,
      singleProduct: false,
      requestShipping: !!cart.cart?.items.find((i) => !i.product.digital),
    };
  }

  const getCartToken = async (): Promise<string | null | undefined> => {
    let token: string | null | undefined = cartToken;

    if (orderDetails.singleProduct && cartItemOptions) {
      token = getTempStripeCartToken();
      if (!token) {
        await singleProductHandler
          .createNewCart({
            productCode: orderDetails.code,
            cartItemOptions,
          })
          .then((response) => {
            token = response;
          });
      }
    }

    return token;
  };

  const handleApproveExpress = async (ev: PaymentRequestPaymentMethodEvent, tempCartToken?: string | null) => {
    return dispatch(
      approveExpressCheckout({
        shippingData: {
          shippingAddress: ev.shippingAddress,
          billingDetails: ev.paymentMethod?.billing_details,
          payerEmail: ev.payerEmail,
          payerName: ev.payerName,
          payerPhone: ev.payerPhone,
        },
        cartToken: tempCartToken,
      })
    )
      .unwrap()
      .then(() =>
        waitForOrderToBeReady(tempCartToken).then(() => {
          if (!tempCartToken) {
            return;
          }

          ev.complete("success");
          removeTempStripeCartToken();
          router.push(getOrderStatusPath(tempCartToken), undefined, { locale: router.locale });
        })
      )
      .catch((error) => {
        ev.complete("fail");
        toastServerError(error);
      });
  };

  useEffect(() => {
    if (stripe && orderDetails) {
      // remove if for some reason it was left from previous attempt to buy
      removeTempStripeCartToken();
      const pr = stripe.paymentRequest({
        country: "US",
        currency: orderDetails.currency.toLowerCase(),
        total: {
          label: "Order",
          amount: orderDetails.amount,
        },
        requestPayerName: true,
        requestPayerEmail: true,
        requestPayerPhone: true,
        requestShipping: orderDetails.requestShipping,
        disableWallets: ["browserCard"],
      });

      pr.on("shippingoptionchange", async (ev) => {
        const cartToken = await getCartToken();
        await dispatch(
          updateExpressCheckout({
            updateData: {
              shippingOption: ev.shippingOption,
              paymentMethod: PaymentMethodCode.STRIPE,
            },
            cartToken,
          })
        ).then((response) => {
          ev.updateWith(response.payload.updatedData);
        });
      });
      pr.on("shippingaddresschange", async (ev) => {
        const cartToken = await getCartToken();
        await dispatch(
          updateExpressCheckout({
            updateData: {
              shippingAddress: ev.shippingAddress,
              paymentMethod: PaymentMethodCode.STRIPE,
            },
            cartToken,
          })
        ).then((response) => {
          ev.updateWith(response.payload.updatedData);
        });
      });
      pr.on("paymentmethod", async (ev) => {
        const cartToken = await getCartToken();
        const clientSecret = await cartService
          .initExpressCheckout(
            {
              method: PaymentMethodCode.STRIPE,
            },
            cartToken
          )
          .then((response) => {
            return response.paymentOrderId;
          });

        if (clientSecret && stripe) {
          const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(
            clientSecret,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            { payment_method: ev.paymentMethod.id },
            { handleActions: false }
          );

          if (confirmError) {
            ev.complete("fail");
          } else if (paymentIntent.status === "requires_action") {
            // Let Stripe.js handle the rest of the payment flow.
            const { error } = await stripe.confirmCardPayment(clientSecret);
            if (error) {
              ev.complete("fail");
            } else {
              return handleApproveExpress(ev, cartToken);
            }
          } else {
            return handleApproveExpress(ev, cartToken);
          }
        }
        return true;
      });
      pr.on("cancel", () => {
        removeTempStripeCartToken();
      });
      pr.canMakePayment().then((methods) => {
        if (methods) {
          setPaymentMethods(methods);
          setPaymentRequest(pr);
        }
      });
    }
  }, [stripe, product, cart.cart, cartItemOptions]);

  useEffect(() => {
    if (paymentRequest) {
      paymentRequest?.update({
        total: {
          label: "Order",
          amount: orderDetails.amount,
        },
      });
    }
  }, [cartItemOptions]);

  if (paymentRequest) {
    return <PaymentButton onClick={() => paymentRequest?.show()} paymentMethods={paymentMethods} disabled={disabled} />;
  }

  return null;
};

const StripeExpress: FC<TCheckoutFormProps> = ({ cartToken, product, cartItemOptions, disabled }) => {
  const config = useAppSelector(selectConfig);
  const stripe = getStripe(config?.config?.stripe_public_key);

  return (
    <Elements stripe={stripe}>
      <CheckoutForm cartToken={cartToken} product={product} cartItemOptions={cartItemOptions} disabled={disabled} />
    </Elements>
  );
};

export default StripeExpress;
