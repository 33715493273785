/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/ban-types */

export interface IServerValidationErrorParams {
  [key: string]: string | number;
}

export interface IServerValidationError {
  key: string;
  params: IServerValidationErrorParams;
}

export interface IServerValidationErrors {
  [key: string]: IServerValidationError;
}

export interface IServerError {
  code: number;
  message: string;
  errors?: IServerValidationErrors;
}

export interface IGeoLocalization {
  country_code: string;
  locale_code: string;
  currency: string;
}
/**
 * @deprecated - create new one with correct place
 */
export enum TranslationDomain {
  COMMON = "common",
  CART = "cart",
  VALIDATION_ERROR = "validation",
  CHECKOUT = "checkout",
  NAVIGATION = "navigation",
  ERROR = "error",
  COUPON = "coupon",
  USER = "user",
  PRODUCT = "product",
  FEATURES = "features",
  PLANS = "plans",
  VIN = "vin",
}

export type TState = {
  code: string;
  name: string;
  abbreviation: string;
};
export type TCountry = {
  code: string;
  name: string;
  states: TState[];
  zipCodeFormat?: string;
};
