import { FC } from "react";

import { Divider, Flex, Text } from "@chakra-ui/react";

interface IDividerWithTextProps {
  text: string;
}

const DividerWithText: FC<IDividerWithTextProps> = ({ text }) => (
  <Flex data-test-id={`dividerWithText_${text}`} align="center" w="full">
    <Divider borderColor="gray.200" />
    <Text padding="2" whiteSpace="nowrap" color="#606060">
      {text}
    </Text>
    <Divider borderColor="gray.200" />
  </Flex>
);

export default DividerWithText;
