import { createContext, useState, ReactNode, FC } from "react";

type UserProviderProps = {
  children: ReactNode;
};
type TUserContext = {} | null;
type TUserSelectContext = any;

export const UserContext = createContext<TUserContext>(null);
export const UserSelectContext = createContext<TUserSelectContext>(null);

export const UserProvider: FC<UserProviderProps> = ({ children }) => {
  const [value, setValue] = useState(null);

  const selectValue = (value) => {
    setValue(value);
  };

  return (
    <UserContext.Provider value={value}>
      <UserSelectContext.Provider value={(value) => selectValue(value)}>{children}</UserSelectContext.Provider>
    </UserContext.Provider>
  );
};
