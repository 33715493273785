import { AxiosPromise } from "axios";

import { API_ROUTES } from "@utils/api-routes";
import api from "@utils/axios";

import { IRedeemCode } from "../types";
import { IOrdersHistory } from "./types";

export const DEFAULT_ORDER_HISTORY_LIMIT = 10;

export interface IGetUserOrderHistoryParams {
  page: number;
  limit?: number;
}

export const getUserOrderHistory = ({
  page,
  limit = DEFAULT_ORDER_HISTORY_LIMIT,
}: IGetUserOrderHistoryParams): AxiosPromise<IOrdersHistory> =>
  api.get<IOrdersHistory>(API_ROUTES.getUserOrders, {
    params: {
      page,
      limit,
    },
  });

export const getOrderItemRedeemCodes = (orderToken: string, itemIdentifier: number): AxiosPromise<IRedeemCode[]> =>
  api.get<IRedeemCode[]>(API_ROUTES.getOrderItemRedeemCodes(orderToken, itemIdentifier));
