import { useEffect, useState } from "react";

import { getCookie, setCookie } from "cookies-next";
import { useRouter } from "next/router";

import { removeTempStripeCartToken } from "@components/checkout/utils/express/utils";
import { mergeLocalizationSources, parseJSONCookie } from "@modules/Localization/utils";
import axios from "@utils/axios/internal";

import config from "./config";
import { TLocalization } from "./types";

/**
 * @description returns the user's localization, for locale change use default next.js locale change
 * @example const router = useRouter(); router.push(router.pathname, router.pathname, { locale: 'en' });
 * @returns {object} { currency: string, locale: string, country: string }
 */
export const useUserLocalization = (): TLocalization & {
  setCurrency: (value: string) => void;
} => {
  const { locale } = useRouter();
  const data = parseJSONCookie(getCookie(config.name) as string) as TLocalization;

  const [userLocalization, setUserLocalization] = useState<TLocalization>(data);

  const setCurrency = (value, reload = true): void => {
    const localization = { ...userLocalization, currency: value } as TLocalization;
    setUserLocalization(localization);
    setCookie(config.name, JSON.stringify(localization), { httpOnly: false });
    removeTempStripeCartToken();

    // We have to force to reload the page to update the currency
    if (reload) {
      window.location.reload();
    }
  };

  const fetchLocalization = async () => {
    const data = await axios
      .get("/api/localization")
      .then((res) => res?.data?.data)
      .catch(() => {});

    const newLocalization = mergeLocalizationSources(data, userLocalization, locale);

    setUserLocalization(newLocalization);
    setCookie(config.name, JSON.stringify(newLocalization), { httpOnly: false });
  };

  useEffect(() => {
    if (!userLocalization?.country || !userLocalization?.locale || !userLocalization?.currency) {
      fetchLocalization();
    }
  }, []);

  return { ...userLocalization, setCurrency };
};

export const getUserLocalization = (): TLocalization => {
  const data = parseJSONCookie(getCookie(config.name) as string) as TLocalization;

  return { ...data };
};
